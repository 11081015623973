import { createSlice } from '@reduxjs/toolkit'

import { encryptStorage } from '@/services'

let INITIAL_STATE = {
  loggedIn: false,
  idle: false,
  user: null,
  token: null,
  rol: []
}
const data = encryptStorage.getItemStorage('auth', true)

if (data) INITIAL_STATE = JSON.parse(data)

export const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    logIn: (state, action) => {
      action.payload.loggedIn = true
      action.payload.idle = false
      return action.payload
    },

    logOut: () => {
      return {
        loggedIn: false,
        idle: false,
        user: null,
        token: null,
        rol: []
      }
    },

    idleTimeExpired: (state, action) => {
      state.idle = action.payload
      encryptStorage.setItemStorage('auth', state, true)
      return state
    },

    updateLogin: (state, action) => {
      return { ...state, ...action.payload }
    }
  }
})

// Action creators are generated for each case reducer function
export const { idleTimeExpired, logIn, logOut, updateLogin } = authSlice.actions

export default authSlice.reducer
