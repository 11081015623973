import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

export const ErrorMessage = ({ query }) => {
  if (!query.error?.message) return null

  return (
    <Alert severity="error">
      <AlertTitle>{'¡ Ocurrio un error !'}</AlertTitle>
      {query.error?.message}
    </Alert>
  )
}
