const indexedDb = window.indexedDB

let db

const conexion = indexedDb.open('Historial', 2)

conexion.onsuccess = () => {
  db = conexion.result
}

conexion.onupgradeneeded = e => {
  db = e.target.result
  db.createObjectStore('requests', {
    keyPath: 'time'
  })
}

conexion.onerror = error => {
  console.error('Error indexedDB ', error)
}

class HistoryBackup {
  addCollection(data) {
    const trasaccion = db.transaction(['requests'], 'readwrite')
    const coleccionObjetos = trasaccion.objectStore('requests')
    coleccionObjetos.add(data)
    return new Promise((resolve, reject) => {
      conexion.onsuccess = e => resolve(e.target.result)
      conexion.onerror = e => reject(e)
    })
  }

  limitRecords(data) {
    const trasaccion = db.transaction(['requests'], 'readwrite')
    const coleccionObjetos = trasaccion.objectStore('requests')
    const keysRequest = coleccionObjetos.getAllKeys()
    keysRequest.onsuccess = event => {
      const keys = event.target.result
      if (keys.length >= 50000) {
        for (let i = 0; i < 5000; i++) {
          coleccionObjetos.delete(keys[i])
        }
      }
      coleccionObjetos.add(data)
    }
    return new Promise((resolve, reject) => {
      coleccionObjetos.onsuccess = e => resolve(e.target.result)
      coleccionObjetos.onerror = e => reject(e)
    })
  }

  getCollection(time) {
    const trasaccion = db.transaction(['requests'], 'readonly')
    const coleccionObjetos = trasaccion.objectStore('requests')
    const conexion = coleccionObjetos.get(time)

    return new Promise((resolve, reject) => {
      conexion.onsuccess = e => resolve(e.target.result)
      conexion.onerror = e => reject(e)
    })
  }

  updateCollection(data) {
    const trasaccion = db.transaction(['requests'], 'readwrite')
    const coleccionObjetos = trasaccion.objectStore('requests')
    coleccionObjetos.put(data)
    return new Promise((resolve, reject) => {
      coleccionObjetos.onsuccess = e => resolve(e.target.result)
      coleccionObjetos.onerror = e => reject(e)
    })
  }

  deleteCollection(time) {
    const trasaccion = db.transaction(['requests'], 'readwrite')
    const coleccionObjetos = trasaccion.objectStore('requests')
    coleccionObjetos.delete(time)
    return new Promise((resolve, reject) => {
      coleccionObjetos.onsuccess = e => resolve(e.target.result)
      coleccionObjetos.onerror = e => reject(e)
    })
  }

  getAllCollection() {
    const trasaccion = db.transaction(['requests'], 'readonly')
    const coleccionObjetos = trasaccion.objectStore('requests')

    return new Promise((resolve, reject) => {
      if ('getAll' in coleccionObjetos) {
        coleccionObjetos.getAll().onsuccess = event =>
          resolve(event.target.result)
      } else {
        return []
      }
    })
  }

  async clearDatabase() {
    const trasaccion = db.transaction(['requests'], 'readwrite')
    const coleccionObjetos = trasaccion.objectStore('requests')
    return new Promise((resolve, reject) => {
      const clear = coleccionObjetos.clear()

      clear.onsuccess = e => resolve(e.target.readyState)
      clear.onerror = e => reject(e)
    })
  }
}

export const historyBackup = new HistoryBackup()
