import { axios } from '@/boot'

const path = 'v1/assigned-doctor'

class Assigned {
  deleteAssignedDoctor(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${path}/${id}`)
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  }

  postAssignedDoctor({ doctor_id, patient_id }) {
    return new Promise((resolve, reject) => {
      axios
        .post(path, { doctor_id, patient_id })
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  }
}

export const assignedDoctorService = new Assigned()
