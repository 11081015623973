import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { userService } from '@/services'

const INITIAL_STATE = []

export const actionGetDoctors = createAsyncThunk(
  'doctors/getDoctors',
  async () => {
    const data = await userService.getUsers({ rol: 'doctor' })
    return data
  }
)

export const doctorSlice = createSlice({
  name: 'doctors',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(actionGetDoctors.fulfilled, (state, action) => {
      return action.payload
    })
  }
})

// Action creators are generated for each case reducer function
// export const { actionGetDoctors } = doctorSlice.actions

export default doctorSlice.reducer
