import { useEffect, useState } from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Chip from '@mui/material/Chip'

import { icdService } from '@/services'

export const AutocompleteUnderlying = ({ onChange, data, label, color, medical_history_id, helperText }) => {
  const [icd10, setIcd10] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [value, setValue] = useState([])

  useEffect(() => {
    if (data) setValue([...data])
  }, [data])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== '' && search && search.length > 2) {
        setLoading(true)
        icdService
          .getSearchUnderlying(search, medical_history_id)
          .then(setIcd10)
          .finally(() => setLoading(false))
      } else clearTimeout(delayDebounceFn)
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [search])

  return (
    <Autocomplete
      multiple
      disableClearable
      loading={loading}
      filterSelectedOptions
      loadingText="Cargando..."
      noOptionsText="Sin resultados"
      value={value}
      options={icd10}
      getOptionLabel={option => option.disease}
      isOptionEqualToValue={(option, value) => option.disease === value.disease}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tooltip key={index} title={option.disease}>
            <Chip label={option?.disease} style={{ textOverflow: 'ellipsis', maxWidth: '300px' }} {...getTagProps({ index })} />
          </Tooltip>
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          onChange={e => {
            if (e.target.value !== '') setSearch(e.target.value)
            else setIcd10([])
          }}
          onKeyDown={event => {
            if (event.key === 'Backspace') {
              event.stopPropagation()
            }
          }}
          helperText={helperText}
        />
      )}
      sx={{ bgcolor: color }}
      onChange={(_, value) => {
        setValue(value)
        onChange(value)
      }}
    />
  )
}
