import { axios } from '@/boot'

const path = 'v1/file'

class File {
  getFile(filename) {
    return new Promise((resolve, reject) => {
      axios
        .get(path, { params: { filename } })
        .then(res => resolve(res.data))
        .catch(e => reject(e))
    })
  }
}

export const fileService = new File()
