import '@/components/init'
import { createStore, Provider as ProviderJotai } from 'jotai'

import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import moment from 'moment'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers'

import { ThemeProviderCustom } from './theme'
import { AppRouters } from './routers/AppRouter'
import WraperUtilities from './hoc/WraperUtilities'
import store from './store'

import 'moment/dist/locale/es'

import 'react-toastify/dist/ReactToastify.css'
import 'react-phone-input-2/lib/material.css'

moment.locale('es')

const storeJotai = createStore()
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 1000
    }
  }
})

function App() {
  return (
    <ProviderJotai store={storeJotai}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ThemeProviderCustom>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
              <WraperUtilities>
                <AppRouters />
              </WraperUtilities>
            </LocalizationProvider>
            <ToastContainer draggable pauseOnHover limit={3} autoClose={3000} theme="colored" position="top-center" />
          </ThemeProviderCustom>
        </Provider>
      </QueryClientProvider>
    </ProviderJotai>
  )
}

export default App
