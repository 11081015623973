import { axios } from '@/boot'

class Recomendation {
  path = 'v1/general-recomendation'

  postGeneralRecomendation(
    { recommendation, prescription_id = undefined },
    abortController
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${this.path}`,
          { recommendation },
          { signal: abortController?.signal || null }
        )
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  }

  getGeneralRecomendations(abortController) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.path}`, { signal: abortController?.signal || null })
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  }

  deleteGeneralRecomendation(id, abortController) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.path}/${id}`, {
          signal: abortController?.signal || null
        })
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  }

  patchGeneralRecomendation(
    id,
    { recommendation, prescription_id = undefined },
    abortController
  ) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${this.path}/${id}`,
          { recommendation },
          { signal: abortController?.signal || null }
        )
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  }
}

export const recomendationsService = new Recomendation()
