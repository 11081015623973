import { useSelector } from 'react-redux'

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

import { IMAGENOLOGIA } from '@/constants'

function ImagingAutocomplete({ value, onChange, helperText, error, label = 'Buscar', multiple }) {
  const products = useSelector(state => state.reducerProducts)
  const filter = products.filter(prod => prod.category.name === IMAGENOLOGIA)
  return (
    <Autocomplete
      multiple={multiple}
      disableClearable
      filterSelectedOptions
      noOptionsText="Sin registros"
      value={value}
      filterOptions={createFilterOptions({
        matchFrom: 'all',
        stringify: prod => prod.name + prod.description
      })}
      options={filter}
      autoHighlight
      groupBy={prod => prod.category.name}
      getOptionDisabled={prod => !prod.state || prod.category.name !== IMAGENOLOGIA}
      getOptionLabel={product => product.name}
      renderOption={(props, product) =>
        product.category.name === IMAGENOLOGIA ? (
          <Box component="li" {...props}>
            {product.name}
          </Box>
        ) : null
      }
      renderInput={params => (
        <TextField {...params} autoComplete="new-text" label={label} error={error} helperText={helperText} />
      )}
      onChange={(e, value) => {
        onChange(value)
      }}
    />
  )
}

export default ImagingAutocomplete
