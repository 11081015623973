import { useState } from 'react'

import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'

import { format_name_single } from '@/helpers'
import ListItemBranchOffice from './ListItemBranchOffice'
import NotificationButton from '@/components/NotificationButton'
import { rolesType } from '@/constants'
import { NetworkStatus } from '@/components'

const ListItemCustom = ({ primary, secondary, icon }) => {
  return (
    <ListItem>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText
        primary={primary}
        secondary={secondary}
        secondaryTypographyProps={{
          variant: 'caption'
        }}
      />
    </ListItem>
  )
}

const UserButtonMenu = ({ user, rol }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  return (
    <>
      <IconButton onClick={handleClick}>
        <ManageAccountsIcon sx={{ color: 'white' }} />
      </IconButton>
      <Typography sx={{ display: { xs: 'none', md: 'block' } }}>
        {[
          format_name_single(user),
          user?.branch_office?.name,
          user?.store?.name,
          user?.store?.simec_store_id
        ].join(' - ')}
      </Typography>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <List sx={{ width: 250 }}>
          <ListItemCustom primary={format_name_single(user)} />
          <ListItemCustom
            primary={user?.branch_office?.name || ''}
            secondary="Sucursal"
          />
          <ListItemCustom
            primary={`${user?.store?.name || ''} ${
              user?.store?.simec_store_id || ''
            }`.trim()}
            secondary="Almacen"
          />
          <ListItemCustom
            icon={<NotificationButton />}
            primary="Notificiones"
          />
          {rol.includes(rolesType.DOCTOR) && (
            <ListItemBranchOffice user={user} />
          )}
          <NetworkStatus />
        </List>
      </Menu>
    </>
  )
}

export default UserButtonMenu
