import axios from '@/boot/axios'

const path = 'v1/auth'
class AuthService {
  login({ email, password }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${path}/login`, {
          email,
          password
        })
        .then(response => {
          const token = response.data.token
          const user = response.data.user
          const roles = user?.user_rol?.map(item => item.rol.name)
          resolve({
            user,
            token,
            rol: roles,
            loggedIn: true,
            idle: false
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  logout() {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${path}/logout`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  verifyPassword(email = '', password = '') {
    return new Promise((resolve, reject) => {
      axios
        .post(`${path}/verify-password`, { email, password })
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  }

  changePassword({ email, password, confirm_password }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${path}/change-password`, {
          email,
          password,
          confirm_password
        })
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  }

  updateTokenDoctor({ branch_office_id, store_id }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${path}/update-token-doctor`, { branch_office_id, store_id })
        .then(res => {
          const token = res.data.token
          const user = res.data.user
          const roles = user?.user_rol?.map(item => item.rol.name)
          resolve({
            user,
            token,
            rol: roles,
            loggedIn: true,
            idle: false
          })
        })
        .catch(e => reject(e))
    })
  }
}

export const authService = new AuthService()
