import { createSlice } from '@reduxjs/toolkit'
const INITIAL_STATE = []

export const uploadFileSlice = createSlice({
  name: 'upload-file',
  initialState: INITIAL_STATE,
  reducers: {
    setFilesToUpload: (state, action) => {
      return [...state, action.payload]
    },

    updateFilesMinio: (state, action) => {
      const response = state.map((item, i) =>
        i === action.payload.index ? { ...item, ...action.payload.data } : item
      )
      return response
    },

    deleteFiles: () => {
      return INITIAL_STATE
    }
  }
})

// Action creators are generated for each case reducer function
export const { deleteFiles, setFilesToUpload, updateFilesMinio } =
  uploadFileSlice.actions

export default uploadFileSlice.reducer
