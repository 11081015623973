import { useEffect, useState } from 'react'

import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import WifiIcon from '@mui/icons-material/Wifi'
import WifiOffIcon from '@mui/icons-material/WifiOff'
import Avatar from '@mui/material/Avatar'

export const NetworkStatus = ({ rounded }) => {
  const [status, setStatus] = useState(true)

  useEffect(() => {
    function changeStatus() {
      setStatus(navigator.onLine)
    }
    window.addEventListener('online', changeStatus)
    window.addEventListener('offline', changeStatus)
    return () => {
      window.removeEventListener('online', changeStatus)
      window.removeEventListener('offline', changeStatus)
    }
  }, [])

  // return status
  //   ? showNotify.success('Conectado a internet')
  //   : showNotify.warning('Sin conexión a internet')

  if (rounded) {
    return (
      <Tooltip title={status ? 'Conectado' : 'Sin internet'}>
        <Avatar sx={{ width: 25, height: 25, bgcolor: 'transparent' }}>
          {status ? (
            <WifiIcon color="secondary" />
          ) : (
            <WifiOffIcon color="inherit" />
          )}
        </Avatar>
      </Tooltip>
    )
  }

  return (
    <ListItem>
      <ListItemIcon>
        <Avatar sx={{ width: 25, height: 25, bgcolor: 'transparent' }}>
          {status ? (
            <WifiIcon color="success" />
          ) : (
            <WifiOffIcon color="inherit" />
          )}
        </Avatar>
      </ListItemIcon>
      <ListItemText primary={status ? 'Conectado' : 'Sin internet'} />
    </ListItem>
  )
}
