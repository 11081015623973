import { useNavigate } from 'react-router-dom'

import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import WarningAmberIcon from '@mui/icons-material/WarningAmber'

export const AccesDenied = () => {
  const navigate = useNavigate()
  return (
    <Container maxWidth="md">
      <Stack alignItems="center" justifyContent="end" height="50vh" spacing={3}>
        <WarningAmberIcon color="error" sx={{ fontSize: 100 }} />
        <Typography variant="h3" color="gray" textAlign="center">
          Acceso denegado
        </Typography>

        <Alert severity="warning" color="error" icon={false}>
          <AlertTitle>Warning</AlertTitle>
          <Typography textAlign="center" variant="subtitle1">
            Su cuenta no tiene los permisos necesarios para acceder a esta
            página.
          </Typography>
          <Typography textAlign="center" variant="body1">
            Por favor, póngase en contacto con el administrador del sistema para
            obtener más información.
          </Typography>
        </Alert>
        <Button
          fullWidth={false}
          variant="outlined"
          sx={{ width: '100px' }}
          onClick={() => {
            navigate(-1)
          }}
        >
          Volver
        </Button>
      </Stack>
    </Container>
  )
}
