import { numberRound } from './round_number'

export const units = ['HORA', 'DIA', 'SEM', 'MES']
export const quantities = ['1', '2', '4', '8', '12']

export const unitsJSON = {
  HORA: 'hr',
  DIA: 'día(s)',
  SEM: 'semana(s)',
  MES: 'mes(es)'
}

export const unitsToOptions = {
  HORA: {
    label: 'hr',
    type: units[0]
  },
  DIA: {
    label: 'dia',
    type: units[1]
  },
  SEM: {
    label: 'semana',
    type: units[2]
  },
  MES: {
    label: 'mes',
    type: units[3]
  }
}

export const options = [
  {
    label: 'hr',
    type: units[0]
  },
  {
    label: 'dia',
    type: units[1]
  },
  {
    label: 'semana',
    type: units[2]
  },
  {
    label: 'mes',
    type: units[3]
  }
]

export const convertToHours = (value, from = '') => {
  if (!value) return 0
  const result = numberRound(value, 2)

  switch (from) {
    case units[0]:
      return numberRound(result, 2)

    case units[1]:
      return numberRound(result * 24, 2)

    case units[2]:
      return numberRound(result * 7 * 24, 2)

    case units[3]:
      return numberRound(result * 30 * 24, 2)

    default:
      return 0
  }
}
