import React from 'react'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import axios from '../boot/axios'
import { useGlobalContext } from '../contexts/MainContext'
import { format_name } from '../helpers/format_name'

function HistoryUnclosedDialog({ open, handleClose, handleLogout, user }) {
  const { setLoading } = useGlobalContext()
  const [unclose, setUnclosed] = React.useState([])
  React.useEffect(() => {
    setLoading(true)
    axios
      .get(`v1/medical-history-detail/unclosed/${user.id}`)
      .then(response => {
        if (response.data.length === 0) handleLogout()
        else setUnclosed(response.data)
      })
      .finally(() => setLoading(false))
  }, [])
  return (
    <Dialog open={open} maxWidth="sm">
      <DialogContent>
        <DialogTitle>Historias sin CERRAR</DialogTitle>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Paciente</TableCell>
                <TableCell colSpan="100%"># Historial</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unclose.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {format_name(item?.medical_history?.patient || '')}
                  </TableCell>
                  <TableCell>{item?.history_detail_number || ''}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Mantener sesión
        </Button>
        <Button variant="outlined" onClick={handleLogout}>
          Salir igualmente
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default HistoryUnclosedDialog
