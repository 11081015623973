import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useSearchParamsValues = () => {
  const [params, setParams] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()

  const handleAddParams = () => {
    const aux = {}
    for (const item of params) {
      if (!item.value) searchParams.delete(item.key)
      else searchParams.append(item.key, item.value)
    }
    searchParams.forEach((value, key) => {
      aux[key] = value
    })

    setSearchParams(aux)
  }

  useEffect(() => {
    handleAddParams()
  }, [params])

  return { setParams }
}
