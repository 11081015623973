import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

export const DialogCustom = ({
  children,
  open,
  handleClose,
  title,
  titleRender,
  back,
  actions,
  maxWidth = 'lg',
  fullScreen,
  ...rest
}) => {
  return (
    <Dialog
      {...rest}
      fullWidth={!fullScreen}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      open={open}
      scroll="paper"
    >
      <DialogTitle>
        <Stack
          direction="row"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={2}>
            {title && (
              <Typography ml={3} variant="h6">
                {title}
              </Typography>
            )}
            {titleRender && <div>{titleRender}</div>}
          </Stack>
          {back ? (
            <IconButton edge="start" onClick={back}>
              <ArrowBackIcon color="primary" />
            </IconButton>
          ) : (
            <IconButton edge="start" onClick={handleClose}>
              <CloseIcon color="primary" />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: 0, m: 0 }}>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  )
}
