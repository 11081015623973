import { Fragment } from 'react'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'

import TableCustom from '@/components/TableCustom'
import ButtonsFilesMinio from './imaging_detail/ButtonsFilesMinio'
import { IMAGENOLOGIA } from '@/constants'

const handleFilter = (item, subCategory) => {
  if (!item) return []
  const data = item.imaging_detail
  if (subCategory === IMAGENOLOGIA) {
    return data
  }

  const filter = data.filter(
    detail => detail.product.sub_category === subCategory
  )
  return filter
}

function HistoryTableImaging({ imaging, label }) {
  return (
    <Table stickyHeader size="small">
      <TableBody>
        {imaging.map(item => (
          <Fragment key={item.id}>
            <TableRow selected>
              <TableCell align="left" colSpan="100%">
                <Chip
                  label={<Typography variant="caption">{item.type}</Typography>}
                  size="small"
                  color="primary"
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan="100%">
                <TableCustom
                  length={handleFilter(item, label).length || 0}
                  rows={handleFilter(item, label) || []}
                  columns={[
                    { id: 'name', label: 'Nombre', minWidth: '40%' },
                    {
                      id: 'description',
                      label: 'Descripcion',
                      minWidth: '30%'
                    },
                    {
                      id: 'files',
                      label: 'Archivos',
                      minWidth: '30%',
                      render: row =>
                        row?.files_minio?.map(file => (
                          <ButtonsFilesMinio
                            key={file.id}
                            edit={false}
                            item={file}
                            patient={item.patient}
                          />
                        ))
                    }
                  ]}
                />
              </TableCell>
            </TableRow>
          </Fragment>
        ))}
      </TableBody>
    </Table>
  )
}

export default HistoryTableImaging
