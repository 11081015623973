import { useDispatch, useSelector } from 'react-redux'

import IconButton from '@mui/material/IconButton'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import BrightnessIcon from '@mui/icons-material/Brightness4'
import Tooltip from '@mui/material/Tooltip'
import { toggleDarkMode } from '@/store/dark-mode'

export const ButtonDarkMode = () => {
  const dispatch = useDispatch()
  const { darkMode } = useSelector(state => state)

  return (
    <Tooltip title={darkMode ? 'Modo Claro' : 'Modo Oscuro'}>
      <IconButton onClick={() => dispatch(toggleDarkMode())}>
        {darkMode ? (
          <BrightnessIcon color="secondary" />
        ) : (
          <DarkModeIcon color="secondary" />
        )}
      </IconButton>
    </Tooltip>
  )
}
