import { axios } from '@/boot'
const path = 'v1/user'

class UserService {
  getUsers({ take = 0, page = 0, rol = 'paciente' }) {
    return new Promise((resolve, reject) => {
      axios
        .get(path, { params: { take, page, rol } })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  searchUser({ text, rol = '' }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/search-all-user`, { params: { text, rol } })
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  getMedicinePatient(patienId, take = 0, page = 0) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/prescription/patient/${patienId}`, { params: { take, page } })
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  getUser(user_id = '', abortController) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/${user_id}`, { signal: abortController?.signal || null })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  createPatient(user) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/auth/register/patient', user)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  updateUser(user) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${path}/${user.id}`, user)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  getUserPoints(patient_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/get-points-patient/${patient_id}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }
}

export const userService = new UserService()
