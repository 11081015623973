import { useEffect, useState } from 'react'

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import InputAdornment from '@mui/material/InputAdornment'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import FormHelperText from '@mui/material/FormHelperText'

export const SelectAutoSave = ({ label, data, onChange, options, color, helperText }) => {
  const [editBlood, setEditBlood] = useState(true)
  const [value, setValue] = useState('')

  useEffect(() => {
    if (data) setValue(data)
  }, [data])

  return (
    <FormControl sx={{ bgcolor: color }}>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        value={value}
        disabled={editBlood}
        onChange={e => {
          onChange(e.target.value)
          setValue(e.target.value)
          setEditBlood(!editBlood)
        }}
        startAdornment={
          <InputAdornment position="start">
            <IconButton onClick={() => setEditBlood(!editBlood)}>
              <EditIcon fontSize="small" color={editBlood ? 'success' : undefined} />
            </IconButton>
          </InputAdornment>
        }
      >
        {options.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}
