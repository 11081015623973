import { encryptStorage } from '@/services'

let session = {
  loggedIn: false,
  idle: false,
  user: null,
  token: null,
  rol: []
}
const data = encryptStorage.getItemStorage('auth', true)

if (data) session = JSON.parse(data)

export const INITIAL_STATE = session
