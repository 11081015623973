import { axios } from '@/boot'
const path = 'v1/intern'

export const internService = {
  getTemplateAdmissionFormById: admissionFormId => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/find-one/${admissionFormId}`)
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },

  getTemplateAdmissionFormByPatientId: (patientId, { order = 'asc', by = 'createdAt', take = 0, page = 0, all = true }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/get-admission-forms/${patientId}`, {
          params: { order, by, take, page, all }
        })
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },

  getHospitalizedPatients: (areaId, patientId, listPatientId, { take = 0, page = 0, all = true }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/hospitalized-patients`, {
          params: { take, page, all, areaId, patientId, listPatientId }
        })
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },

  getLaboratories: admissionFormId => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/get-samples/${admissionFormId}`)
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },

  getSampleResult: admissionFormId => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/get-sample-result/${admissionFormId}`)
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },
  getMedicalHistoryByAdmissionFormId: admissionFormId => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/medical-history/${admissionFormId}`)
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },

  postAdmissionForm: ({
    patientId,
    bedId,
    dateOfEntry,
    reasonForAdmission,
    specialIntruccions,
    treatingDoctorId,
    triageLevelId,
    therapeuticLimitationId
  }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${path}/admission-form`, {
          patientId,
          bedId,
          dateOfEntry,
          reasonForAdmission,
          specialIntruccions,
          treatingDoctorId,
          triageLevelId,
          therapeuticLimitationId
        })
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },

  patchAssignedBed: ({ admissionFormId, bedId, areaId }) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${path}/assigned-bed`, { admissionFormId, bedId, areaId })
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },

  patchAddDiet: ({ admissionFormId, dietId }) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${path}/add-diet`, { admissionFormId, dietId })
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },

  patchAddLaboratories: ({ admissionFormId, products = [] }) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${path}/add-samples`, { admissionFormId, products })
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },

  patchAdissionForm: (
    admissionFormId,
    {
      triageLevelId,
      therapeuticLimitationId,
      dateOfEntry,
      typeAdmissionForm,
      reasonForAdmission,
      specialIntruccions,
      treatingDoctorId,
      blocked
    }
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${path}/admission-form/${admissionFormId}`, {
          triageLevelId,
          therapeuticLimitationId,
          dateOfEntry,
          typeAdmissionForm,
          reasonForAdmission,
          specialIntruccions,
          treatingDoctorId,
          blocked
        })
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  }
}
