import { useEffect, useState } from 'react'

import Typography from '@mui/material/Typography'

import { useGlobalContext } from '@/contexts/MainContext'
import HistoryTableImaging from './HistoryTableImaging'
import { imagenologyService } from '@/services'
import { IMAGENOLOGIA } from '@/constants'
import { DialogCustom } from '@/components'

function HistoryImagingDialog({ open, handleClose, medical_detail_id }) {
  const [imaging, setImaging] = useState([])
  const { setLoading } = useGlobalContext()

  useEffect(() => {
    setLoading(true)
    imagenologyService
      .getImagingMedicalDetailById(medical_detail_id)
      .then(data => {
        setImaging(data)
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <DialogCustom
      open={open}
      title={'Imagenologias emitidas desde esta Historia'}
      handleClose={handleClose}
    >
      <div style={{ minHeight: '40vh', padding: 10 }}>
        {imaging.length === 0 && (
          <Typography color="gray" textAlign="center">
            El paciente no tiene Imagenologias en este Historial
          </Typography>
        )}
        <HistoryTableImaging imaging={imaging} label={IMAGENOLOGIA} />
      </div>
    </DialogCustom>
  )
}

export default HistoryImagingDialog
