import { memo, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import LogoutIcon from '@mui/icons-material/Logout'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

import HistoryUnclosedDialog from '@/components/HistoryUnclosedDialog'
import { validateRol, validateRouteForUser } from '@/helpers'
import { authService } from '@/services'
import { iconColor, itemsList, itemsSetting } from './ItemsList'
import { useDispatch, useSelector } from 'react-redux'
import { useGlobalContext } from '@/contexts'
import { cleanLocalStorage } from '@/utils'
import { PublicRoutesList } from '@/routers/routerList'
import { deleteFiles } from '@/store/upload-file'
import { statusUploadMinio } from '@/components/UploadFilesMinio'
import { logOut } from '@/store/auth'
import { rolesType } from '@/constants'

const denseList = false

export const ListItemCustom = ({ icon, name, path, onClick }) => {
  return (
    <NavLink to={path} style={{ textDecoration: 'none' }}>
      {({ isActive }) => (
        <Card
          variant="elevation"
          sx={theme => ({
            bgcolor: isActive ? theme.palette.secondary.main : undefined
          })}
        >
          <ListItemButton dense disableGutters onClick={onClick}>
            <ListItem
              key={name}
              dense
              disableGutters
              disablePadding
              divider={false}
            >
              <ListItemIcon
                sx={{ minWidth: 0, mx: 2, justifyContent: 'center' }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText
                primary={name}
                primaryTypographyProps={{
                  sx: theme => ({
                    color:
                      theme.palette.mode === 'dark'
                        ? isActive
                          ? theme.palette.common.black
                          : theme.palette.common.white
                        : theme.palette.common.black
                  })
                }}
              />
            </ListItem>
          </ListItemButton>
        </Card>
      )}
    </NavLink>
  )
}

export const ListContainer = ({ children }) => {
  return (
    <List
      dense={denseList}
      sx={{ px: 1, display: 'flex', flexDirection: 'column' }}
    >
      {children}
    </List>
  )
}

export const LogoutListItem = ({ handleLogout, setUnclosed, user }) => {
  return (
    <ListItemButton
      dense
      disableGutters
      onClick={e => {
        if (validateRol(user?.user_rol, rolesType.DOCTOR)) setUnclosed(true)
        else {
          handleLogout()
        }
      }}
    >
      <ListItem dense disableGutters disablePadding divider={false}>
        <ListItemIcon sx={{ minWidth: 0, mx: 2, justifyContent: 'center' }}>
          <LogoutIcon color={iconColor} />
        </ListItemIcon>
        <ListItemText primary="Salir" />
      </ListItem>
    </ListItemButton>
  )
}

export const DrawerList = memo(() => {
  const {
    auth: { user, rol },
    uploadFile
  } = useSelector(state => state)
  const dispatch = useDispatch()
  const [unclosed, setUnclosed] = useState(false)
  const { setLoading, showConfirmation } = useGlobalContext()
  const navigate = useNavigate()

  const handleLogout = () => {
    const pending = uploadFile?.find(
      item => item.status === statusUploadMinio.PENDING
    )
    if (!pending) {
      closeSession()
      return
    }

    showConfirmation(
      'Cerrar',
      <Typography component="span">
        <b>Tiene archivos sin completar.</b>
        <br />
        Al cerrar se cancelará la subida de los archivos sin completar.
      </Typography>,
      () => {
        for (const file of uploadFile) {
          file?.bucket?.abort()
        }
        setTimeout(() => {}, 100)
        closeSession()
      }
    )
  }

  const closeSession = () => {
    setLoading(true)
    authService
      .logout()
      .then(() => {
        cleanLocalStorage()
        dispatch(deleteFiles())
        dispatch(logOut())
        navigate(PublicRoutesList.LOGIN, { replace: true })
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <ListContainer>
        {itemsList.map(
          item =>
            validateRouteForUser(rol, item.path) && (
              <ListItemCustom
                key={item.name}
                path={item.path}
                icon={item.icon}
                name={item.name}
              />
            )
        )}
        <Divider />
        {itemsSetting.map(item => (
          <ListItemCustom
            key={item.name}
            path={item.path}
            icon={item.icon}
            name={item.name}
          />
        ))}
        <Divider />
        <LogoutListItem
          handleLogout={handleLogout}
          setUnclosed={setUnclosed}
          user={user}
        />
      </ListContainer>
      {unclosed && (
        <HistoryUnclosedDialog
          open={unclosed}
          handleClose={() => setUnclosed(false)}
          handleLogout={handleLogout}
          user={user}
        />
      )}
    </>
  )
})
