import { createSlice } from '@reduxjs/toolkit'
import { stateSalesType } from '@/constants'

const INITIAL_STATE = {
  description: '',
  sale_id: '',
  patient: null,
  transfer: 0,
  points: 0,
  state: stateSalesType.NO_PAGADO
}

export const saleSlice = createSlice({
  name: 'sales',
  initialState: INITIAL_STATE,
  reducers: {
    actionAddSale: (state, action) => {
      return action.payload
    },
    actionSaleTransfer: (state, action) => {
      return { ...state, ...action.payload }
    },
    actionClearSale: () => {
      return { ...INITIAL_STATE }
    }
  }
})

// Action creators are generated for each case reducer function
export const { actionAddSale, actionClearSale, actionSaleTransfer } =
  saleSlice.actions

export default saleSlice.reducer
