import { encryptStorage } from '@/services'
import store from '@/store'

export const cleanLocalStorage = () => {
  const {
    auth: { token, loggedIn }
  } = store.getState()

  const data = ['doctors', 'labs', 'products', 'category']
  for (const name of data) {
    localStorage.removeItem(name)
  }
  const response = encryptStorage.getItemStorage('auth', true)
  if (response) {
    const session = JSON.parse(response)
    if (loggedIn) {
      if (session?.token?.token === token?.token) {
        localStorage.removeItem('auth')
      }
    } else {
      localStorage.removeItem('auth')
    }
  }
}
