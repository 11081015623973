import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { useAsyncError } from 'react-router-dom'

const errorMessage = error => {
  if (error?.response?.data?.message) {
    return error?.response?.data?.message
  }
  return error.message
}

const ErrorMessage = ({ title = '', message = '' }) => {
  const error = useAsyncError()
  if (!error && !message) return null

  return (
    <Alert severity="error">
      <AlertTitle>{title || 'Ocurrio un error!'}</AlertTitle>
      {message || errorMessage(error)}
    </Alert>
  )
}

export default ErrorMessage
