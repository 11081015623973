import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import UploadIcon from '@mui/icons-material/Upload'
import CircularProgress from '@mui/material/CircularProgress'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Tooltip from '@mui/material/Tooltip'
import Avatar from '@mui/material/Avatar'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { s3AWS } from '@/boot/aws-sdk'
import { useGlobalContext } from '@/contexts'
import { imagenologyService } from '@/services'
import { useDispatch, useSelector } from 'react-redux'
import { pushImagingDetailArr } from '@/store/imaging-detail'
import { showNotify } from '@/providers/Notify'
import { deleteFiles, updateFilesMinio } from '@/store/upload-file'
import { imagingType } from '@/constants'

const indicatorWith = 250

export const statusUploadMinio = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  ABORT: 'ABORT'
}

const UploadProgress = ({ data, index }) => {
  const [hoverUpload, setHoverUpload] = useState(false)
  const [progress, setProgress] = useState(0)
  const [upload, setUpload] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    handleSaveMinio(data)
  }, [])
  const handleUpdateImaging = (response, name) => {
    imagenologyService
      .postFileMinio({
        etag: response.ETag,
        key: response.Key,
        name,
        url: response.Location,
        state: imagingType.PENDIENTE,
        imaging_detail_id: data.imagingDetailId,
        versionId: response.VersionId
      })
      .then(data => {
        dispatch(pushImagingDetailArr(data))
        dispatch(
          updateFilesMinio({
            index,
            data: { status: statusUploadMinio.COMPLETED }
          })
        )
        showNotify.success('Archivo guardado')
      })
  }

  const handleSaveMinio = ({ buffer, imagingDetailId, patientId, status }) => {
    try {
      if (!imagingDetailId) {
        return
      }

      const params = {
        Body: buffer,
        Bucket: import.meta.env.VITE_S3_BUCKET,
        ContentType: buffer.type,
        Key: `${import.meta.env.VITE_S3_DICOM_FOLDER}/${patientId}/${
          buffer.name
        }`
      }

      const bucket = s3AWS.upload(params)

      setUpload(bucket)
      dispatch(updateFilesMinio({ index, data: { bucket } }))

      bucket
        .on('httpUploadProgress', evt => {
          setProgress(Math.round((evt.loaded / evt.total) * 100))
        })
        .send((err, response) => {
          if (response) {
            dispatch(
              updateFilesMinio({
                index,
                data: { status: statusUploadMinio.COMPLETED }
              })
            )
            handleUpdateImaging(response, buffer.name)
          }
          if (err) {
            dispatch(
              updateFilesMinio({
                index,
                data: { status: statusUploadMinio.ABORT }
              })
            )
            showNotify.info('Cancelado por el usuario')
            console.error(err.message)
          }
        })
    } catch (e) {
      console.error('ERROR AL CARGAR EL ARCHIVO', e)
    }
  }

  const handleCancel = () => {
    upload?.abort()
  }
  return (
    <ListItem
      sx={{ pl: 1 }}
      secondaryAction={
        <Stack
          sx={{ p: 1 }}
          onMouseEnter={() => setHoverUpload(true)}
          onMouseLeave={() => setHoverUpload(false)}
        >
          {data.status === statusUploadMinio.COMPLETED && (
            <CheckCircleIcon color="success" />
          )}
          {data.status === statusUploadMinio.PENDING &&
            (!hoverUpload ? (
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                  thickness={5}
                  size={30}
                  variant={progress <= 0 ? 'indeterminate' : 'determinate'}
                  value={progress}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                  >
                    {`${Math.round(progress)}%`}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Tooltip title="Cancelar la carga" placement="top-start">
                <IconButton
                  sx={{ width: 30, height: 30 }}
                  onClick={handleCancel}
                >
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            ))}
        </Stack>
      }
      divider
    >
      <ListItemIcon>
        <Avatar sx={{ width: 24, height: 24 }}>
          <UploadIcon />
        </Avatar>
      </ListItemIcon>
      <ListItemText
        primary={
          <Tooltip placement="top-start" title={data.buffer.name}>
            <Typography
              variant="body2"
              sx={{
                display: 'inline-block',
                width: indicatorWith - 120,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {data.buffer.name}
            </Typography>
          </Tooltip>
        }
      />
    </ListItem>
  )
}

export const UploadFilesMinio = () => {
  const files = useSelector(state => state.uploadFile)
  const dispatch = useDispatch()
  const { showConfirmation } = useGlobalContext()

  useEffect(() => {
    const handleBeforeUnload = event => {
      event.preventDefault()
      event.returnValue = '' // Esto es necesario en algunos navegadores
      return '¿Estás seguro de que quieres abandonar la página?'
    }
    if (files.length > 0) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  const handleClose = () => {
    const pending = files?.find(
      item => item.status === statusUploadMinio.PENDING
    )
    if (!pending) {
      dispatch(deleteFiles())
      return
    }

    showConfirmation(
      'Cerrar',
      <Typography component="span">
        <b>Tiene archivos sin completar.</b>
        <br />
        Al cerrar se cancelará la subida de los archivos sin completar.
      </Typography>,
      () => {
        for (const file of files) {
          file?.bucket?.abort()
        }
        setTimeout(() => {}, 100)
        dispatch(deleteFiles())
      }
    )
  }

  return (
    <Box
      sx={{ position: 'fixed', bottom: 0, right: { xs: 0, sm: 20 }, zIndex: 2 }}
    >
      {files?.length > 0 && (
        <Card
          sx={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 12,
            borderTopLeftRadius: 12
          }}
        >
          <CardHeader
            title={
              <Stack direction="row" justifyContent="space-between">
                <Typography>Lista de archivos</Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Stack>
            }
          />
          <CardContent
            sx={{
              p: 0,
              m: 0,
              width: { xs: '100%', md: indicatorWith },
              overflow: 'auto',
              maxHeight: 250
            }}
          >
            {files?.map((item, index) => (
              <UploadProgress
                key={item.imagingDetailId + index}
                data={item}
                index={index}
              />
            ))}
          </CardContent>
        </Card>
      )}
    </Box>
  )
}
