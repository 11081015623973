import SecondaryLayout from '@/layouts/SecondaryLayout'
import { PrivateRouteList } from '@/routers/routerList'

const rutas = [
  {
    to: PrivateRouteList.LABORATORY.ROOT,
    label: 'Resultados'
  },
  {
    to: PrivateRouteList.LABORATORY.HISTORY,
    label: 'Historial'
  },
  {
    to: PrivateRouteList.LABORATORY.REFERENCE_VALUE,
    label: 'Valores de referencia'
  },
  { to: PrivateRouteList.LABORATORY.SUB_LABORATORY, label: 'Sub-laboratorios' },
  {
    to: PrivateRouteList.LABORATORY.ANALYSIS,
    label: 'Análisis'
  },
  {
    to: PrivateRouteList.LABORATORY.WHATSAPP,
    label: 'WhatsApp'
  }
]

function LaboratoryLayout() {
  return <SecondaryLayout rutas={rutas} />
}

export default LaboratoryLayout
