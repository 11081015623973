import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { calculateAge, format_name } from '@/helpers'
import { processDate } from '@/utils'

export const CardPatientAdmissionDetail = ({ patient }) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h4">{format_name(patient)}</Typography>
      <Typography variant="caption">
        <b>Género: </b>
        {patient?.gender || ''}
        <br />
        <b>Edad: </b>
        {calculateAge(patient?.birthdate)}
        <br />
        <b>Fecha Nac: </b>
        {processDate(patient?.birthdate).formattedDate}
        <br />
      </Typography>
    </Stack>
  )
}
