import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  _nextID: 1,
  notifyList: []
}
export const notifySlice = createSlice({
  name: 'notify',
  initialState: INITIAL_STATE,
  reducers: {
    clearAll: () => {
      return INITIAL_STATE
    },

    deleteNotify: (state, action) => {
      const filtered = state.notifyList.filter(
        item => item.id !== action.payload.id
      )
      return { ...state, notifyList: filtered }
    },

    addNotify: (state, action) => {
      state.notifyList.push({
        id: state._nextID,
        ...action.payload
      })
      state._nextID++
      return state
    }
  }
})

// Action creators are generated for each case reducer function
export const { addNotify, clearAll, deleteNotify } = notifySlice.actions
export const selectNotify = state => ({
  _nextID: state.notify._nextID,
  notifyList: state.notify.notifyList
})

export default notifySlice.reducer
