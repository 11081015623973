import * as yup from 'yup'

export const nurseEvolutionSchema = yup.object().shape({
  specialty: yup.object().nullable().required('Este campo es requerido'),
  evolution: yup.string().required('Campo requerido')
})

export const INITIAL_NURSE_EVOLUTION = {
  specialty: null,
  evolution: ''
}
