import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid2'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import DeleteIcon from '@mui/icons-material/Delete'
import Stack from '@mui/material/Stack'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import Alert from '@mui/material/Alert'

import ImagingAutocomplete from './ImagingAutocomplete'
import { useGlobalContext } from '@/contexts/MainContext'
import { numberRound } from '@/helpers/round_number'
import { saleService } from '@/services/sale_service'
import { DialogCustom } from '@/components/DialogCustom'
import { stateSalesType } from '@/constants'
import { showNotify } from '@/providers/Notify'
import { TableCustom } from '@/components'
import { productService } from '@/services'

function DialogImagingSale({ open, handleClose, patient, medical_history_detail_id, medicalHistory }) {
  const [select, setSelect] = useState(null)
  const [imaging, setImaging] = useState([])
  const [description, setDescription] = useState('')
  const { setLoading } = useGlobalContext()
  const { user } = useSelector(state => state.auth)
  const [mostSelling, setMostSelling] = useState([])

  const handleDelete = item => {
    const deleteItem = imaging.reduce((acc, act) => {
      if (act.id === item.id) return acc
      return [...acc, act]
    }, [])
    setImaging(deleteItem)
  }

  const handleSubmit = () => {
    if (!imaging.length) {
      return showNotify.info('Seleccione una imagenologia')
    }
    setLoading(true)

    const total = imaging.reduce((sum, i) => Number(sum) + Number(i.price), 0)

    const model = {
      total_price: numberRound(total, 2),
      state: stateSalesType.NO_PAGADO,
      description,
      patient_id: patient.id,
      cashier_id: user.id,
      transfer: 0.0,
      sale_detail: imaging.map(prod => ({
        id: '',
        sale_price: numberRound(prod.price, 2),
        discount: 0.0,
        invoiced: false,
        product_id: prod.id,
        quantity: 1,
        name: prod.name,
        meet_id: '',
        meet: null
      })),
      id: '',
      medical_history_detail_id
    }

    saleService
      .createSale(model)
      .then(() => {
        showNotify.success('Imagenologia Emitida correctamente')
        clear()
      })
      .finally(() => setLoading(false))
  }

  const clear = () => {
    setDescription('')
    setImaging([])
    setSelect(null)
  }

  useEffect(() => {
    const razon = medicalHistory?.diagnostic?.map(item => item?.name || '').join(', ')
    setDescription(razon)
    handleGetMostSeller()
  }, [])

  const handleGetMostSeller = async () => {
    try {
      const resp = await productService.getProductsMostSellingImaging()
      setMostSelling(resp)
    } catch (e) {
      showNotify.error(e)
    }
  }

  return (
    <DialogCustom
      open={open}
      maxWidth="md"
      handleClose={handleClose}
      title={'Emitir Imagenología'}
      actions={
        <Stack direction="row" spacing={2}>
          <Button fullWidth={false} variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button fullWidth={false} onClick={handleSubmit}>
            Emitir imagenologia
          </Button>
        </Stack>
      }
    >
      <div style={{ minHeight: '40vh' }}>
        <Grid container spacing={2} padding={2} justifyContent="center">
          <Grid size={{ xs: 12 }}>
            <Alert severity="info">Agrege un estudio complementario desde el buscador o la lista.</Alert>
          </Grid>
          <Grid size={{ xs: 12, md: 7 }}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, md: 8 }}>
                <ImagingAutocomplete
                  value={select}
                  onChange={value => setSelect(value)}
                  helperText="Seleccione y agrege una imagenología"
                />
              </Grid>
              <Grid size={{ xs: 12, md: 2 }}>
                <Button
                  onClick={() => {
                    if (!select) {
                      return showNotify.info('Seleccione una imagenologia')
                    }

                    let exist = false
                    imaging.forEach(item => {
                      if (item.id === select.id) exist = true
                    })

                    if (exist) return showNotify.info('Ya esta seleccionado')

                    setImaging([...imaging, select])
                    setSelect(null)
                  }}
                >
                  Agregar
                </Button>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <TextField
                  label="Razón"
                  multiline
                  rows={2}
                  value={description}
                  onChange={event => setDescription(event.target.value)}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <TableCustom
                  length={imaging?.length || 0}
                  rows={imaging || []}
                  columns={[
                    { id: 'name', label: 'Nombre' },
                    {
                      id: 'order',
                      label: 'Ordenar',
                      align: 'center',
                      render: row => (
                        <IconButton onClick={() => handleDelete(row)}>
                          <DeleteIcon color="error" />
                        </IconButton>
                      )
                    }
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{ xs: 12, md: 5 }}>
            <TableCustom
              title="Estudios complementarios mas solicitados"
              length={mostSelling?.length || 0}
              rows={mostSelling || []}
              columns={[
                { id: 'name', label: 'Nombre' },
                {
                  id: 'actions',
                  label: 'Agregar',
                  align: 'center',
                  render: row => (
                    <Stack direction="row">
                      <IconButton
                        disabled={imaging.some(item => item.id === row.id)}
                        onClick={() => {
                          let exist = false
                          imaging.forEach(item => {
                            if (item.id === row.id) exist = true
                          })

                          if (exist) {
                            showNotify.info('Ya esta seleccionado')
                            return
                          }

                          setImaging([...imaging, row])
                        }}
                      >
                        <ShoppingCartIcon />
                      </IconButton>
                    </Stack>
                  )
                }
              ]}
            />
          </Grid>
        </Grid>
      </div>
    </DialogCustom>
  )
}

export default DialogImagingSale
