import { useEffect, useState } from 'react'

import Typography from '@mui/material/Typography'
import TimeLineLaboratory from '../../tab_laboratory/TimeLineLaboratory'
import { DialogCustom } from '@/components/DialogCustom'
import { laboratoryService } from '@/services'
import { useResponsive } from '@/hooks'

function LaboratoriesDialog({ open, handleClose, medical_history_detail_id }) {
  const [laboratory, setLaboratory] = useState([])
  const [loading, setLoading] = useState(false)
  const match = useResponsive('down', 'md')

  useEffect(() => {
    if (medical_history_detail_id) {
      setLoading(true)
      laboratoryService
        .getLaboratoryMedicalHistoryDetail({ medical_history_detail_id })
        .then(data => setLaboratory(data))
        .finally(() => setLoading(false))
    }
  }, [])

  return (
    <DialogCustom
      open={open}
      fullScreen={match}
      handleClose={handleClose}
      title={'Laboratorios emitidos desde esta historia'}
    >
      <div style={{ minHeight: '90vh', padding: 10 }}>
        {laboratory.length === 0 && !loading && (
          <Typography textAlign="center" color="lightgray">
            Sin laboratorios
          </Typography>
        )}
        {laboratory.map(item => (
          <TimeLineLaboratory key={item.id} data={item} />
        ))}
      </div>
    </DialogCustom>
  )
}
export default LaboratoriesDialog
