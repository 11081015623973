import axios from '../boot/axios.js'
const path = 'v1/laboratory-template'
class LaboratoryTemplateService {
  create(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${path}`, data)
        .then(response => resolve(response.data))
        .catch(e => reject(e))
    })
  }

  getLaboratoryTemplate({ id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/${id}`)
        .then(response => resolve(response.data))
        .catch(e => reject(e))
    })
  }

  getTemplateLaboratories() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}`)
        .then(response => resolve(response.data))
        .catch(e => reject(e))
    })
  }
}

export const laboratoryTemplateService = new LaboratoryTemplateService()
