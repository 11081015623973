import { useQuery } from '@tanstack/react-query'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { laboratoryTemplateService } from '@/services'

function AutocompleteTemplate({ label = 'Plantillas', value, onChangeCallBack }) {
  const templates = useQuery({
    queryKey: ['get-templates-laboratories'],
    queryFn: () => laboratoryTemplateService.getTemplateLaboratories(),
    placeholderData: previousData => previousData || []
  })

  return (
    <Autocomplete
      noOptionsText="Sin sugerencias"
      value={value}
      loading={templates.isLoading}
      options={templates.data}
      autoHighlight
      isOptionEqualToValue={option => option.id === value.id}
      getOptionLabel={template => template.name}
      renderOption={(props, template) => (
        <Box component="li" {...props}>
          {template.name}
        </Box>
      )}
      renderInput={params => <TextField {...params} label={label} />}
      onChange={(e, value) => {
        onChangeCallBack(value)
      }}
    />
  )
}

export default AutocompleteTemplate
