import Stack from '@mui/material/Stack'
import LinearProgress from '@mui/material/LinearProgress'

import { ErrorMessage } from './ErrorMessage'

export const QueryActions = ({ query, loadingComponent }) => {
  return (
    <Stack>
      {query.isLoading && (loadingComponent || <LinearProgress />)}
      <ErrorMessage query={query} />
    </Stack>
  )
}
