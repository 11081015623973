import axios from '../boot/axios'
const path = 'v1/sales'

class SaleService {
  async getSales({ take = 0, page = 0 }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}`, { params: { take, page } })
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  }

  async getPatientSales({ take = 0, page = 0, patient_id = '' }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/patient`, { params: { take, page, patient_id } })
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  async getReport({ from = '', to = '', state = 'PAGADO' }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/report`, { params: { from, to, state } })
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  async getEmployee({ from = '', to = '', user_id = '' }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/employee`, { params: { from, to, user_id } })
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  async getSaleById(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/${id}`)
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  }

  async createSale(sale) {
    return new Promise((resolve, reject) => {
      axios
        .post(path, sale)
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  }

  async createNewSale(sale) {
    return new Promise((resolve, reject) => {
      axios
        .post(path + '/new-sale', sale)
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  }

  async deleteSale({ id = '' }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${path}/${id}`)
        .then(response => resolve(response.data))
        .catch(e => {
          reject(e)
        })
    })
  }

  async cancelSale({ id = '', email = '', password = '', description = '' }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${path}/cancel/${id}`, { email, password, description })
        .then(response => resolve(response.data))
        .catch(e => reject(e))
    })
  }
}

export const saleService = new SaleService()
