import { useEffect, useState } from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'

export const AutocompleteRecord = ({ onChange, data, label, color, helperText, ...others }) => {
  const [value, setValue] = useState([])

  useEffect(() => {
    if (data) setValue([...data])
  }, [data])

  return (
    <Autocomplete
      {...others}
      multiple
      disableClearable
      noOptionsText=""
      filterSelectedOptions
      size="small"
      value={value}
      options={[]}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tooltip key={index} arrow title={option} enterDelay={1000}>
            <Chip
              size="small"
              label={option}
              style={{ textOverflow: 'ellipsis', maxWidth: '250px' }}
              {...getTagProps({ index })}
            />
          </Tooltip>
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          onKeyDown={event => {
            if (event.key === 'Backspace') {
              event.stopPropagation()
            }
          }}
          helperText={helperText}
        />
      )}
      sx={{ bgcolor: color }}
      onChange={(_, value) => {
        setValue(value)
        onChange(value)
      }}
    />
  )
}
