import { toast } from 'react-toastify'
import store from '@/store'

import { addNotify } from '@/store/notifications'

export const variants = {
  error: 'error',
  warning: 'warning',
  info: 'info',
  default: 'default',
  success: 'success'
}

export const variantName = {
  error: 'Error',
  warning: 'Warning',
  info: 'Info',
  default: 'Default',
  success: 'Success'
}

const errorMessageValidator = msg => {
  if (typeof msg === 'string') return msg
  if (!msg.response) return 'Ocurrio un error: ' + msg

  const data = msg.response?.data
  if (typeof data?.message === 'string') {
    if (data?.message?.includes('Internal server')) return 'Ocurrio un error'
    if (data?.message?.includes('/')) return 'Ocurrio un error'
    return data.message
  }

  if (Array.isArray(data?.message) && data?.message?.length > 0) {
    return data.message[0]
  }

  return 'Ocurrio un error desconocido'
}

export const showNotify = {
  storeMessage(msg, variant = variants.default) {
    if (msg?.code === 'ERR_CANCELED') return
    store.dispatch(
      addNotify({
        message: msg?.response?.data || msg,
        variant
      })
    )
  },

  success(msg) {
    toast.success(msg)
    this.storeMessage(msg, variants.success)
  },
  warning(msg) {
    toast.warning(msg)
    this.storeMessage(msg, variants.warning)
  },
  info(msg) {
    toast.info(msg)
    this.storeMessage(msg, variants.info)
  },
  error(msg) {
    toast.error(errorMessageValidator(msg))
    this.storeMessage(msg, variants.error)
  },
  async promise(fetch, { pending, success, error }) {
    return await toast.promise(fetch, {
      pending: pending || 'Cargando ...',
      success: success || 'Finalizado',
      error: error || 'Ocurrio un error'
    })
  }
}
