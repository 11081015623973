import axios from '../boot/axios'

const path = 'v1/laboratory'

class LaboratoryService {
  getLaboratories({ from, to }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}`, { params: { from, to } })
        .then(response => {
          resolve(response.data)
        })
        .catch(e => reject(e))
    })
  }

  getPatientLaboratories({ patient_id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}`, { params: { patient_id } })
        .then(response => {
          resolve(response.data)
        })
        .catch(e => reject(e))
    })
  }

  createLaboratory({ patient_id, laboratory_id, type, description, samples }) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${path}`,
          { type, description, samples },
          { params: { patient_id, laboratory_id } }
        )
        .then(response => {
          resolve(response.data)
        })
        .catch(e => reject(e))
    })
  }

  updateLaboratory({
    patient_id,
    laboratory_id,
    type,
    description,
    sample_result
  }) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${path}`,
          { type, description, sample_result },
          { params: { patient_id, laboratory_id } }
        )
        .then(response => {
          resolve(response.data)
        })
        .catch(e => reject(e))
    })
  }

  putLaboratory(
    id,
    { description, observation, report_date, type, sample_result }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${path}/${id}`, {
          description,
          observation,
          report_date,
          type,
          sample_result
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(e => reject(e))
    })
  }

  getLaboratoryMedicalHistoryDetail({
    medical_history_detail_id,
    take = 0,
    p = 0
  }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/medical-history-detail`, {
          params: { medical_history_detail_id, take, p }
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(e => reject(e))
    })
  }

  getLaboratoryPatientAll(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/patient-all/${id}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(e => reject(e))
    })
  }

  getComparativeLaboratoriesByPatient(
    { patientId, from, to },
    abortController
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/new-comparison/${patientId}`, {
          params: { from, to },
          signal: abortController?.signal || undefined
        })
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  }
}

export const laboratoryService = new LaboratoryService()
