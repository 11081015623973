import { useEffect } from 'react'

export const useAbortController = () => {
  const abortController = new AbortController()

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return { abortController }
}
