import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

import { processDate } from '@/utils'

export const TimeAgoEs = ({ createdAt = '', date = '', title = '' }) => {
  if (!date) return <Typography variant="caption">No date</Typography>

  return (
    <Typography variant="caption">
      <Tooltip title={createdAt ? 'Creado: ' + processDate(createdAt).fullDateTime : processDate(date).fullDateTime}>
        <span>{`${title} ${processDate(date).timeAgo}`.trim()}</span>
      </Tooltip>
    </Typography>
  )
}
