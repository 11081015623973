import React from 'react'

import TimelineContent from '@mui/lab/TimelineContent'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import Stack from '@mui/material/Stack'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import moment from 'moment'
import { format_name } from '@/helpers'

function HistoryTimelineItem({ children, date, doctor, specialty, patient }) {
  return (
    <TimelineItem>
      <TimelineOppositeContent sx={{ display: 'none' }} />
      <TimelineSeparator sx={{ display: { xs: 'none', md: 'flex' } }}>
        <TimelineDot color="primary" />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ px: { xs: 0, md: 1 }, mx: { xs: 0, md: 1 } }}>
        <Paper variant="outlined" sx={{ borderColor: '#f2cdf0', pb: 2, pr: 1 }}>
          <Stack spacing={1} p={1 / 2}>
            <Typography variant="subtitle2">
              {moment(date).format('LL')}
            </Typography>
            {specialty && (
              <Typography variant="caption">
                <b>Especialidad: </b>
                <span style={{ textTransform: 'uppercase' }}>{specialty}</span>
              </Typography>
            )}
            {doctor && (
              <Typography variant="caption">
                <b>Doctor: </b>
                <span style={{ textTransform: 'uppercase' }}>
                  {format_name(doctor)}
                </span>
              </Typography>
            )}
            {patient && (
              <Typography variant="caption">
                <b>Paciente: </b>
                <span style={{ textTransform: 'uppercase' }}>
                  {format_name(patient)}
                </span>
              </Typography>
            )}
          </Stack>
          {children}
        </Paper>
      </TimelineContent>
    </TimelineItem>
  )
}

export default HistoryTimelineItem
