import { useState } from 'react'
import { useSelector } from 'react-redux'

import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import EditNoteIcon from '@mui/icons-material/EditNote'
import ExpandMore from '@mui/icons-material/ExpandMore'

const ItemListFiles = ({ tooltip, icon, label, onClick, disable = false }) => {
  if (disable) return null
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <Tooltip title={tooltip}>
          <ListItemText primary={label} />
        </Tooltip>
      </ListItemButton>
    </ListItem>
  )
}
const ButtonsFilesMinio = ({ item, edit, handleDeteFile, index, patient }) => {
  const [expanded, setExpanded] = useState(false)
  const { token } = useSelector(state => state.auth)

  const handleChange = () => {
    setExpanded(prev => !prev)
  }

  const openDicomFile = (item, Key, fileId) => () => {
    const api = import.meta.env.VITE_API_DICOM
    const minio = import.meta.env.VITE_S3_ENDPOINT
    const Bucket = import.meta.env.VITE_S3_BUCKET
    const a = document.createElement('a')
    const url = `${minio}/${Bucket}/${item.key}`
    const path = `${api}/?names=[${item.name}]&urls=[${url}]&save=${minio}&Bucket=${Bucket}&Key=${Key}&fileId=${fileId}`

    a.href = path
    a.target = '_blank'
    a.click()
  }

  const openFiles = item => () => {
    const api = import.meta.env.VITE_API_DICOM
    const Bucket = import.meta.env.VITE_S3_BUCKET
    const minio = import.meta.env.VITE_S3_ENDPOINT

    const a = document.createElement('a')
    const path = `${api}/?token=${token?.token}&patientId=${
      patient ? patient.id : ''
    }&Bucket=${Bucket}&Key=${item.key}&save=${minio}`

    a.href = path
    a.target = '_blank'
    a.click()
  }

  const downloadFile = item => () => {
    const minio = import.meta.env.VITE_S3_ENDPOINT
    const Bucket = import.meta.env.VITE_S3_BUCKET

    const a = document.createElement('a')
    a.href = `${minio}/${Bucket}/${item.key}`
    a.download = item.name
    a.target = '_blank'
    a.click()
  }

  const validationFile = item => {
    if (item?.name.toString().includes('zip')) return false

    return true
  }

  return (
    <>
      <Card sx={{ my: 1 }}>
        {/* <Tooltip arrow title={item?.name || ''}>
          <Typography variant="body2">{item.name}</Typography>
        </Tooltip> */}
        <List>
          <ItemListFiles
            icon={<DownloadIcon />}
            label="Abrir / Descargar"
            tooltip="Descargar / Abrir archivo"
            onClick={downloadFile(item)}
          />
          <ItemListFiles
            icon={<OpenInNewIcon />}
            label="DICOM"
            tooltip="Abrir / Ver archivo DICOM"
            onClick={openDicomFile(item, item.key, item.id)}
            disable={validationFile(item)}
          />
          <ItemListFiles
            icon={<OpenInBrowserIcon />}
            label="Abrir todos"
            tooltip="Abrir todos los estudios complementarios"
            onClick={openFiles(item)}
          />
          {edit && (
            <ItemListFiles
              icon={<DeleteIcon color="error" />}
              label="Eliminar"
              tooltip="Eliminar estudio"
              onClick={() => handleDeteFile(item.id, index)}
            />
          )}
          {!!item?.annotations?.length && (
            <>
              <ListItem disablePadding>
                <ListItemButton onClick={handleChange}>
                  <ListItemIcon>
                    <EditNoteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Anotaciones" />
                  {expanded ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListItem>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item?.annotations?.map(file => (
                    <ListItem
                      key={file.id}
                      disablePadding
                      secondaryAction={
                        <Tooltip arrow title="Descargar anotación">
                          <IconButton onClick={downloadFile(file)}>
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    >
                      <ListItemIcon
                        sx={{ minWidth: 0, mx: 2, justifyContent: 'center' }}
                      >
                        <Tooltip arrow title="Abrir anotación">
                          <IconButton
                            onClick={openDicomFile(item, item.key, item.id)}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemIcon>
                      <ListItemText
                        secondaryTypographyProps={{
                          textOverflow: 'ellipsis',
                          maxWidth: '150px',
                          fontSize: 12
                        }}
                        secondary={file?.name || ''}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </>
          )}
        </List>
      </Card>
    </>
  )
}

export default ButtonsFilesMinio
