import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

export const TriageLevelRender = ({ color, name }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <div
        style={{
          width: 15,
          height: 15,
          backgroundColor: color,
          borderRadius: '20%'
        }}
      />
      <Typography>{name}</Typography>
    </Stack>
  )
}
