import { CustomToolbar } from '@/layouts/MainLayout/components/CustomToolbar'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'

export const drawerWidthHistory = 370

const RightDrawer = ({
  children,
  matches,
  open,
  container,
  handleDrawerToggle,
  toggleDrawer
}) => {
  return (
    <Box
      component="nav"
      aria-label="patient-information"
      sx={{
        width: { md: open ? drawerWidthHistory : undefined },
        flexShrink: { md: 0 }
      }}
    >
      {matches ? (
        // WEB
        <Drawer
          anchor="right"
          variant="persistent"
          sx={{
            display: { xs: 'none', md: 'flex' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              mt: 8,
              borderRadius: 1,
              height: '92vh',
              width: drawerWidthHistory
            }
          }}
          open={open}
        >
          <div>{children}</div>
        </Drawer>
      ) : (
        // MOBILE
        <Drawer
          anchor="right"
          elevation={0}
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', md: 'none' },
            zIndex: theme => theme.zIndex.drawer - 1,
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidthHistory
            }
          }}
        >
          <CustomToolbar />
          <Box onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <div>{children}</div>
          </Box>
        </Drawer>
      )}
    </Box>
  )
}

export default RightDrawer
