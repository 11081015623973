import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { actionGetDoctors } from '@/store/doctors'
import { actionGetLabs } from '@/store/laboratories'
import { actionGetProducts } from '@/store/products'

const Providers = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actionGetDoctors())
    dispatch(actionGetProducts())
    dispatch(actionGetLabs())
  }, [])

  return null
}

export default Providers
