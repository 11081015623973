import { Suspense, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Await } from 'react-router-dom'

import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import ListItemButton from '@mui/material/ListItemButton'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { authService, branchOfficeService, encryptStorage } from '@/services'
import ErrorMessage from '@/pages/ErrorMessage'
import { PrivateRouteList } from '@/routers/routerList'
import { updateLogin } from '@/store/auth'

const ListItemBranchOffice = ({ user }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleUpdateToken = branch => () => {
    authService
      .updateTokenDoctor({
        branch_office_id: branch.id,
        store_id: branch.stores[0].id
      })
      .then(data => {
        clear()
        encryptStorage.setItemStorage('auth', data, true)
        dispatch(updateLogin(data))
        window.location.href = PrivateRouteList.HOME
      })
  }

  const handleClick1 = () => {
    setOpen(!open)
  }

  const clear = () => {
    setOpen(false)
  }
  return (
    <>
      <ListItemButton onClick={handleClick1}>
        <ListItem disableGutters disablePadding>
          <ListItemText
            primary="Cambiar sucursal"
            primaryTypographyProps={{ fontWeight: 'bold' }}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </ListItemButton>
      {open && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense>
            <Suspense
              fallback={
                <Typography align="center" color="text.secondary">
                  Cargando...
                </Typography>
              }
            >
              <Await
                resolve={branchOfficeService.getBranchOffice()}
                errorElement={<ErrorMessage />}
              >
                {data =>
                  data?.length === 0 ? (
                    <Typography color="divider">Sin datos</Typography>
                  ) : (
                    data?.map(branch => (
                      <ListItemButton
                        key={branch.id}
                        onClick={handleUpdateToken(branch)}
                        sx={{ pl: 3 }}
                        disabled={branch.id === user.branch_office_id}
                      >
                        <ListItem disableGutters disablePadding>
                          <ListItemText
                            primary={branch?.name || 'Sin nombre'}
                            primaryTypographyProps={{
                              variant: 'body1'
                              // color: 'primary'
                            }}
                            secondary={branch?.stores
                              ?.map(item =>
                                `${item.name} ${item.simec_store_id}`.trim()
                              )
                              ?.join(', ')}
                            secondaryTypographyProps={{
                              variant: 'caption',
                              // color: 'text.secondary',
                              color: 'primary'
                            }}
                          />
                        </ListItem>
                      </ListItemButton>
                    ))
                  )
                }
              </Await>
            </Suspense>
          </List>
        </Collapse>
      )}
    </>
  )
}

export default ListItemBranchOffice
