import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = []

export const imagingSlice = createSlice({
  name: 'imaging-detail',
  initialState: INITIAL_STATE,
  reducers: {
    imagingDetailArr: (state, action) => {
      const imagingDetail = action.payload

      const filter = state.map(item =>
        item.id === imagingDetail.id ? imagingDetail : item
      )
      return filter
    },

    setImagingDetailArr: (state, action) => {
      return action.payload
    },

    deleteImaginDetailFile: (state, action) => {
      const file = action.payload

      const filterRes = state.map(item =>
        item.id === file.imaging_detail_id
          ? {
              ...item,
              files_minio: item.files_minio.filter(f => f.id !== file.id)
            }
          : item
      )
      return filterRes
    },

    pushImagingDetailArr: (state, action) => {
      const fileMinio = action.payload
      const response = state.map(item =>
        item.id === fileMinio.imaging_detail_id
          ? { ...item, files_minio: [...item.files_minio, fileMinio] }
          : item
      )
      return response
    },

    cleanImagingDetail: () => {
      return INITIAL_STATE
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  cleanImagingDetail,
  deleteImaginDetailFile,
  imagingDetailArr,
  pushImagingDetailArr,
  setImagingDetailArr
} = imagingSlice.actions

export default imagingSlice.reducer
