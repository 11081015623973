import { useMediaQuery, useTheme } from '@mui/material'
import React, { memo, useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid2'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

const TableRowDesktop = ({ row, index, columns, page }) => {
  return (
    <TableRow tabIndex={-1} key={row.id} hover>
      <TableCell sx={{ color: 'lightgray' }}>{index + 1 + page}</TableCell>
      {columns.map(column => {
        const value = row[column.id]
        return (
          <TableCell key={column.id} align={column.align}>
            {column.format
              ? column.format(value)
              : column.render
              ? column.render(row, index + page)
              : value}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

const CardMobile = ({ columns, row, index, extraRowEnd, page }) => {
  return (
    <Card>
      <CardContent>
        <Grid
          key={row.id}
          sx={{ my: 2 }}
          component={Card}
          container
          justifyContent="space-around"
          spacing={1 / 2}
        >
          {columns.map(column => {
            const value = row[column.id]
            return (
              <React.Fragment key={column.id}>
                <Grid
                  size={{ xs: 3 }}
                  sx={{
                    display: 'flex',
                    direction: 'row',
                    alignItems: 'center'
                  }}
                >
                  <Typography fontSize={11} fontWeight="light" align="right">
                    {column.label}:
                  </Typography>
                </Grid>
                <Grid size={{ xs: 8 }}>
                  {column.format ? (
                    <Typography fontSize={12} fontWeight="medium">
                      {column.format(value)}:
                    </Typography>
                  ) : column.render ? (
                    column.render(row, index + page)
                  ) : (
                    <Typography fontSize={12} fontWeight="medium">
                      {value}
                    </Typography>
                  )}
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Divider />
                </Grid>
              </React.Fragment>
            )
          })}
          {extraRowEnd && <Grid size={{ xs: 12 }}>{extraRowEnd}</Grid>}
        </Grid>
      </CardContent>
    </Card>
  )
}

const maxHeight = '50vh'

function TableCustom({
  title,
  titleRender,
  columns,
  rows = [],
  length,
  rowsPerpage = 10,
  footer = false,
  extraRowEnd
}) {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerpage)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  useEffect(() => {
    setPage(0)
  }, [length])

  return (
    <>
      {matches ? (
        // DESCKTOP
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight }}>
            <Table>
              <TableHead>
                {titleRender && (
                  <TableRow>
                    <TableCell colSpan={columns.length + 1}>
                      {titleRender}
                    </TableCell>
                  </TableRow>
                )}
                {title && (
                  <TableRow>
                    <TableCell colSpan={columns.length + 1}>{title}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell width="5%">#</TableCell>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column?.align}
                      style={{ width: column.minWidth }}
                    >
                      {column?.titleRender && column.titleRender()}
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={columns.length + 1}>
                      Sin datos
                    </TableCell>
                  </TableRow>
                )}
                {footer
                  ? rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRowDesktop
                          key={row.id}
                          page={page * rowsPerPage}
                          row={row}
                          index={index}
                          columns={columns}
                        />
                      ))
                  : rows.map((row, index) => (
                      <TableRowDesktop
                        key={row.id}
                        page={page * rowsPerPage}
                        row={row}
                        index={index}
                        columns={columns}
                      />
                    ))}
                {extraRowEnd && (
                  <TableRow>
                    <TableCell colSpan={columns.length + 1}>
                      {extraRowEnd}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        //  MODILE
        <>
          {titleRender && titleRender}
          {title && React.isValidElement(title) ? (
            title
          ) : (
            <Typography position="sticky" margin={1}>
              {title}
            </Typography>
          )}
          <Box sx={{ maxHeight, overflow: 'auto' }}>
            {rows.length === 0 && <Typography margin={1}>Sin datos</Typography>}
            {footer
              ? rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <CardMobile
                      key={row.id}
                      columns={columns}
                      row={row}
                      extraRowEnd={extraRowEnd}
                      index={index}
                      page={page}
                    />
                  ))
              : rows.map((row, index) => (
                  <CardMobile
                    key={row.id}
                    columns={columns}
                    row={row}
                    extraRowEnd={extraRowEnd}
                    index={index}
                    page={page}
                  />
                ))}
          </Box>
        </>
      )}
      {footer && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={length}
          labelRowsPerPage="Cantidad"
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  )
}

export default memo(TableCustom)
