import { PrivateRouteList } from '@/routers/routerList'
import SecondaryLayout from '@/layouts/SecondaryLayout'

const rutas = [
  {
    to: PrivateRouteList.HEMODIALYSIS.ROOT,
    label: 'Detalle de Procedimiento HEMODIALISIS'
  },
  {
    to: PrivateRouteList.HEMODIALYSIS.CLINICAL_REPORT,
    label: 'Informe Clínico Mensual'
  },
  { to: PrivateRouteList.HEMODIALYSIS.MEDICINES, label: 'Medicinas' },
  { to: PrivateRouteList.HEMODIALYSIS.LABORATORIES, label: 'Laboratorios' },
  {
    to: PrivateRouteList.HEMODIALYSIS.QUICK_VIEW_LABORATORIES,
    label: 'Vista rápida Laboratorios'
  },
  { to: PrivateRouteList.HEMODIALYSIS.IMAGENOLOGY, label: 'Imagenologia' }
]

export default function HemodialysisSessionLayout() {
  return (
    <>
      <SecondaryLayout rutas={rutas} />
    </>
  )
}
