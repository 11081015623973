import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { Controller } from 'react-hook-form'

export const AutocompleteForm = ({
  label,
  control,
  name,
  defaultValue,
  onChange,
  renderOption,
  renderLabel,
  error,
  helperText,
  isOptionEqualToValue,
  getOptionDisabled,
  required,
  readOnly,
  ...others
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Autocomplete
          {...others}
          readOnly={readOnly}
          selectOnFocus
          getOptionLabel={option => (renderLabel ? renderLabel(option) : option)}
          isOptionEqualToValue={(option, value) =>
            isOptionEqualToValue ? isOptionEqualToValue(option, value) : option === value
          }
          value={field.value}
          onChange={(_, newValue) => {
            field.onChange(newValue)
            if (onChange) onChange(newValue)
          }}
          getOptionDisabled={getOptionDisabled}
          renderInput={params => (
            <TextField
              {...params}
              error={error}
              required={required}
              helperText={helperText}
              label={label}
              margin="none"
              onKeyDown={event => {
                if (event.key === 'Backspace') {
                  event.stopPropagation()
                }
              }}
            />
          )}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {renderOption ? renderOption(option) : option}
            </Box>
          )}
        />
      )}
    />
  )
}
