import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axios } from '@/boot'

const INITIAL_STATE = []

export const actionGetCategoryAnalysis = createAsyncThunk(
  'categories/getCategories',
  async () => {
    const resp = await axios.get('v1/category-analysis')
    return resp.data
  }
)

export const categorySlice = createSlice({
  name: 'categories',
  initialState: INITIAL_STATE,
  reducers: {
    actionCategoryAnalysis: (state, action) => {
      return action.payload
    },

    actionPushCategoryAnalysis: (state, action) => {
      return [...state, action.payload]
    },

    actionUpdateCategoryAnalysis: (state, action) => {
      const data = state.map(elem =>
        elem.id === action.payload.id ? action.payload : elem
      )
      return data
    }
  },
  extraReducers: builder => {
    builder.addCase(actionGetCategoryAnalysis.fulfilled, (state, action) => {
      return action.payload
    })
  }
})

// Action creators are generated for each case reducer function
export const {
  actionPushCategoryAnalysis,
  actionUpdateCategoryAnalysis,
  actionCategoryAnalysis
} = categorySlice.actions

export default categorySlice.reducer
