import { PrivateRouteList } from '@/routers/routerList'

const COMMON = {
  [PrivateRouteList.HOME]: true,
  [PrivateRouteList.ACCCESS_DENIED]: true,
  [PrivateRouteList.NOT_FOUND]: true,
  [PrivateRouteList.PROFILE]: true,
  [PrivateRouteList.DAY_OFF]: true,
  [PrivateRouteList.SETTINGS]: true
}

const FARMACIA = {
  ...COMMON,
  [PrivateRouteList.PHARMACY]: true,
  [PrivateRouteList.CASHIER.SALE_PRINT]: true
}

const DOCTOR_HEMODIALISIS = {
  ...COMMON,
  [PrivateRouteList.HEMODIALYSIS.ROOT]: true,
  [PrivateRouteList.HEMODIALYSIS.TURNS]: true,
  [PrivateRouteList.HEMODIALYSIS.SESSIONS]: true,
  [PrivateRouteList.HEMODIALYSIS.PATIENTS]: true,
  [PrivateRouteList.HEMODIALYSIS.SESSION_PDF]: true,
  [PrivateRouteList.HEMODIALYSIS.FORM]: true,
  [PrivateRouteList.HEMODIALYSIS.CLINICAL_REPORT]: true,
  [PrivateRouteList.HEMODIALYSIS.MEDICINES]: true,
  [PrivateRouteList.HEMODIALYSIS.LABORATORIES]: true,
  [PrivateRouteList.HEMODIALYSIS.QUICK_VIEW_LABORATORIES]: true,
  [PrivateRouteList.HEMODIALYSIS.IMAGENOLOGY]: true
}

const ENFERMERA_HEMODIALISIS = {
  ...COMMON,
  [PrivateRouteList.HEMODIALYSIS.TURNS]: true,
  [PrivateRouteList.HEMODIALYSIS.PATIENTS]: true,
  [PrivateRouteList.HEMODIALYSIS.SESSIONS]: true,
  [PrivateRouteList.HEMODIALYSIS.FORM]: true,
  [PrivateRouteList.HEMODIALYSIS.SESSION_PDF]: true
}

const CAJA = {
  ...COMMON,
  [PrivateRouteList.REGISTER]: true,
  [PrivateRouteList.CASHIER.ROOT]: true,
  [PrivateRouteList.CASHIER.CALENDAR]: true,
  [PrivateRouteList.CASHIER.RESCHEDULE]: true,
  [PrivateRouteList.CASHIER.CREDIT_SALE]: true,
  [PrivateRouteList.CASHIER.CREDIT_SALE_DETAIL]: true,
  [PrivateRouteList.CASHIER.REPORT]: true,
  [PrivateRouteList.CASHIER.REPORT_PRINT]: true,
  [PrivateRouteList.CASHIER.LABS]: true,
  [PrivateRouteList.CASHIER.SALE_PRINT]: true
}

const PACIENTE = {}

const DOCTOR = {
  ...COMMON,
  [PrivateRouteList.SCHEDULE]: true,
  [PrivateRouteList.MEDICAL_HISTORY.ROOT]: true,
  [PrivateRouteList.MEDICAL_HISTORY.MEDICAL_HISTORY_SALES]: true,
  [PrivateRouteList.MEDICAL_HISTORY.SPECIALTY]: true,
  [PrivateRouteList.MEDICAL_HISTORY.HISTORY]: true,
  [PrivateRouteList.MEDICAL_HISTORY.LABORATORY]: true,
  [PrivateRouteList.MEDICAL_HISTORY.IMAGENOLOGY]: true,
  [PrivateRouteList.MEDICAL_HISTORY.COMPARATIVE_LABORATORIES]: true,
  [PrivateRouteList.MEDICAL_HISTORY.CERTIFICATE]: true,

  [PrivateRouteList.SALE_LABORATORY]: true,
  [PrivateRouteList.VITAL_SIGNS]: true,
  [PrivateRouteList.IMAGEN.ROOT]: true,
  [PrivateRouteList.IMAGEN.IMAGEN_DETAIL]: true
}

const REGISTRO = {
  ...COMMON,
  [PrivateRouteList.REGISTER]: true
}

const ENFERMERA = {
  ...COMMON,
  [PrivateRouteList.VITAL_SIGNS]: true,
  [PrivateRouteList.SAMPLES]: true
}

const LABORATORIO = {
  ...COMMON,
  [PrivateRouteList.LABORATORY.ROOT]: true,
  [PrivateRouteList.LABORATORY.HISTORY]: true,
  [PrivateRouteList.LABORATORY.REFERENCE_VALUE]: true,
  [PrivateRouteList.LABORATORY.SUB_LABORATORY]: true,
  [PrivateRouteList.LABORATORY.ANALYSIS]: true,
  [PrivateRouteList.SAMPLES]: true,
  [PrivateRouteList.LABORATORY.WHATSAPP]: true
}

const BIOQUIMICA = {
  ...COMMON,
  [PrivateRouteList.VITAL_SIGNS]: true,
  [PrivateRouteList.SAMPLES]: true
}

const IMAGENOLOGIA = {
  ...COMMON,
  [PrivateRouteList.IMAGEN.ROOT]: true,
  [PrivateRouteList.IMAGEN.IMAGEN_DETAIL]: true
}

export const role_permissions = (role, page) => {
  // TODO: El administrador tiene acceso a todas las rutas
  // role.forEach((rol, i) => {
  switch (role[0].rol.name) {
    case 'ADMIN':
      return true

    case 'CAJA':
      return CAJA[page]

    case 'PACIENTE':
      return PACIENTE[page]

    case 'DOCTOR':
      return DOCTOR[page]

    case 'REGISTRO':
      return REGISTRO[page]

    case 'ENFERMERA':
      return ENFERMERA[page]

    case 'FARMACIA':
      return FARMACIA[page]

    case 'LABORATORIO':
      return LABORATORIO[page]

    case 'BIOQUIMICA':
      return BIOQUIMICA[page]

    case 'IMAGENOLOGIA':
      return IMAGENOLOGIA[page]

    case 'DOCTOR_HEMODIALISIS':
      return DOCTOR_HEMODIALISIS[page]

    case 'ENFERMERA_HEMODIALISIS':
      return ENFERMERA_HEMODIALISIS[page]

    default:
      return false
  }
  // });
}
export const validateRol = (roles = [], value) => {
  if (!roles) return false
  const arrRole = roles.map(item => item.rol.name)
  return arrRole.includes(value)
}

export const validateRouteForUser = (userRoles, pathname) => {
  const names = pathname.split('/')
  let route = ''
  if (names.length > 2) {
    route = names[names.length - 1]
  } else {
    route = pathname
  }

  for (const role of userRoles) {
    switch (role) {
      case 'ADMIN':
        // TODO: El administrador tiene acceso a todas las rutas
        return true

      case 'CAJA':
        if (CAJA[route]) return true
        break

      case 'PACIENTE':
        if (PACIENTE[route]) return true
        break

      case 'DOCTOR':
        if (DOCTOR[route]) return true
        break

      case 'REGISTRO':
        if (REGISTRO[route]) return true
        break

      case 'ENFERMERA':
        if (ENFERMERA[route]) return true
        break

      case 'FARMACIA':
        if (FARMACIA[route]) return true
        break

      case 'LABORATORIO':
        if (LABORATORIO[route]) return true
        break

      case 'BIOQUIMICA':
        if (BIOQUIMICA[route]) return true
        break

      case 'IMAGENOLOGIA':
        if (IMAGENOLOGIA[route]) return true
        break

      case 'DOCTOR_HEMODIALISIS':
        if (DOCTOR_HEMODIALISIS[route]) return true
        break

      case 'ENFERMERA_HEMODIALISIS':
        if (ENFERMERA_HEMODIALISIS[route]) return true
        break
      default:
        return false
    }
  }
  return false
}
