import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'

import { TriageLevelRender } from './TriageLevelRender'

const ListItemCustom = ({ onEdit, primary, secondary, editable }) => {
  return (
    <ListItem
      disableGutters
      disablePadding
      secondaryAction={
        editable && (
          <IconButton edge="end" aria-label="delete" onClick={onEdit}>
            <EditIcon />
          </IconButton>
        )
      }
    >
      <ListItemText primary={primary} secondary={secondary} secondaryTypographyProps={{ variant: 'caption' }} />
    </ListItem>
  )
}

export const CardTriageLevel = ({ triageLevel, handleEditTriage }) => {
  return (
    <Stack>
      <List dense>
        <ListItemCustom
          editable
          onEdit={handleEditTriage}
          primary={<TriageLevelRender {...triageLevel} />}
          secondary="Prioridad"
        />
      </List>
    </Stack>
  )
}
