import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar'
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import AssessmentIcon from '@mui/icons-material/Assessment'
import PersonIcon from '@mui/icons-material/Person'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import AssignmentIcon from '@mui/icons-material/Assignment'
import ScienceIcon from '@mui/icons-material/Science'
import BiotechIcon from '@mui/icons-material/Biotech'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import PermMediaIcon from '@mui/icons-material/PermMedia'
import SettingsIcon from '@mui/icons-material/Settings'
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy'

import { PrivateRouteList } from '@/routers/routerList'

export const iconColor = 'primary'
export const drawerWidth = 250

export const itemsList = [
  {
    name: 'Registro de pacientes',
    path: PrivateRouteList.REGISTER,
    icon: <PeopleAltIcon color={iconColor} />
  },
  {
    name: 'Internaciones / Emergencia',
    path: PrivateRouteList.EMERGENCY.ROOT,
    icon: <LocalHospitalIcon color={iconColor} />
  },
  {
    name: 'Dr. Agenda',
    path: PrivateRouteList.SCHEDULE,
    icon: <PermContactCalendarIcon color={iconColor} />
  },
  {
    name: 'Agenda',
    path: PrivateRouteList.CASHIER.CALENDAR,
    icon: <InsertInvitationIcon color={iconColor} />
  },
  {
    name: 'Imagenologia',
    path: PrivateRouteList.IMAGEN.ROOT,
    icon: <PermMediaIcon color={iconColor} />
  },
  {
    name: 'Caja',
    path: PrivateRouteList.CASHIER.ROOT,
    icon: <MonetizationOnIcon color={iconColor} />
  },
  {
    name: 'Farmacia',
    path: PrivateRouteList.PHARMACY,
    icon: <LocalPharmacyIcon color={iconColor} />
  },
  // {
  //   name: 'Ventas a credito',
  //   path: PrivateRouteList.CASHIER.CREDIT_SALE,
  //   icon: <MonetizationOnIcon color={iconColor} />
  // },
  {
    name: 'Historia clinica',
    path: PrivateRouteList.MEDICAL_HISTORY.ROOT,
    icon: <AssignmentIcon color={iconColor} />
  },
  {
    name: 'Signos vitales',
    path: PrivateRouteList.VITAL_SIGNS,
    icon: <LocalHospitalIcon color={iconColor} />
  },
  {
    name: 'Recepción de muestras',
    path: PrivateRouteList.SAMPLES,
    icon: <ScienceIcon color={iconColor} />
  },
  {
    name: 'Laboratorio o Imagenologia emitidas',
    path: PrivateRouteList.MEDICAL_HISTORY.MEDICAL_HISTORY_SALES,
    icon: <ScienceIcon color={iconColor} />
  },
  {
    name: 'Hemodialisis',
    path: PrivateRouteList.HEMODIALYSIS.ROOT,
    icon: <BiotechIcon color={iconColor} />
  },
  {
    name: 'Hemodialisis Turnos',
    path: PrivateRouteList.HEMODIALYSIS.TURNS,
    icon: <BiotechIcon color={iconColor} />
  },
  {
    name: 'Laboratorio',
    path: PrivateRouteList.LABORATORY.ROOT,
    icon: <BiotechIcon color={iconColor} />
  },
  {
    name: 'Reportes',
    path: PrivateRouteList.CASHIER.REPORT,
    icon: <AssessmentIcon color={iconColor} />
  },
  {
    name: 'Especialidad',
    path: PrivateRouteList.MEDICAL_HISTORY.SPECIALTY,
    icon: <ManageAccountsIcon color={iconColor} />
  }
]

export const itemsSetting = [
  {
    name: 'Horarios de trabajo',
    path: PrivateRouteList.PROFILE,
    icon: <PersonIcon color={iconColor} />
  },
  {
    name: 'Dias libres',
    path: PrivateRouteList.DAY_OFF,
    icon: <AccessTimeIcon color={iconColor} />
  },
  {
    name: 'Ajustes de usuario',
    path: PrivateRouteList.SETTINGS,
    icon: <SettingsIcon color={iconColor} />
  }
]
