import { axios } from '@/boot'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {}

export const actionGetLabs = createAsyncThunk(
  'laboratory/getLaboratories',
  async () => {
    const response = await axios.get('v1/products/laboratories')
    const indexed = response.data.reduce((acc, lab) => {
      if (!acc.hasOwnProperty(lab.sub_category)) {
        lab.purchase = false
        return {
          ...acc,
          [lab.sub_category]: lab.state ? [lab] : []
        }
      }
      lab.purchase = false
      if (lab.state) acc[lab.sub_category].push(lab)
      return acc
    }, {})
    const indexes = Object.keys(indexed)
    for (const index of indexes) {
      indexed[index].sort((a, b) => {
        a = a.name.toLowerCase()
        b = b.name.toLowerCase()
        if (a < b) return -1
        if (a > b) return 1
        return 0
      })
    }
    return indexed
  }
)

export const laboratory = createSlice({
  name: 'laboratory',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(actionGetLabs.fulfilled, (state, action) => {
      return action.payload
    })
  }
})

// Action creators are generated for each case reducer function
// export const { actionGetLabs } = laboratory.actions

export default laboratory.reducer
