import { createSlice } from '@reduxjs/toolkit'

const data = JSON.parse(localStorage.getItem('dark'))
const INITIAL_STATE = data || false

export const darkSlice = createSlice({
  name: 'dark-mode',
  initialState: INITIAL_STATE,
  reducers: {
    toggleDarkMode: state => {
      localStorage.setItem('dark', !state)
      return !state
    }
  }
})

// Action creators are generated for each case reducer function
export const { toggleDarkMode } = darkSlice.actions

export default darkSlice.reducer
