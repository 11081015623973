import * as yup from 'yup'

export const addmissionSchema = yup.object().shape({
  bed: yup.object().nullable(),
  dateOfEntry: yup.date().required('Campo requerido'),
  reasonForAdmission: yup.string().required('Campo requerido'),
  specialIntruccions: yup.string().required('Campo requerido'),
  treatingDoctor: yup.object().required('Campo requerido'),
  triageLevel: yup.object().nullable(),
  therapeuticLimitation: yup.object().nullable()
})

export const INITIAL_ADMISSION = {
  bed: null,
  dateOfEntry: new Date(),
  reasonForAdmission: '',
  specialIntruccions: '',
  treatingDoctor: null,
  triageLevel: null,
  therapeuticLimitation: null
}
