import { useEffect, useState } from 'react'
import { useAbortController } from './useAbortController'
import { recomendationsService } from '@/services'

export const useGetGeneralRecomendations = () => {
  const [generalRecomendations, setGeneralRecomendations] = useState([])
  const { abortController } = useAbortController()

  useEffect(() => {
    recomendationsService
      .getGeneralRecomendations(abortController)
      .then(data => setGeneralRecomendations(data))
  }, [])

  return { generalRecomendations, setGeneralRecomendations }
}
