import moment from 'moment'

export const calculateAge = date => {
  if (date && moment(date).isValid()) {
    const birthDate = new Date(date)
    const otherDate = new Date()
    let years = otherDate.getFullYear() - birthDate.getFullYear()

    if (
      otherDate.getMonth() < birthDate.getMonth() ||
      (otherDate.getMonth() === birthDate.getMonth() &&
        otherDate.getDate() < birthDate.getDate())
    ) {
      years--
    }

    return years
  }
  return 0
}
