import { validateRouteForUser } from '@/helpers'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { PrivateRouteList } from './routerList'

export const RoleValidator = ({ children }) => {
  const { rol } = useSelector(state => state.auth)
  const location = useLocation()

  return validateRouteForUser(rol, location.pathname) ? (
    children
  ) : (
    <Navigate to={PrivateRouteList.ACCCESS_DENIED} />
  )
}
