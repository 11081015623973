import { Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

import { PrivateRouteList } from './routerList'

import LoadingPage from '@/pages/Loading'
import MainLayout from '@/layouts/MainLayout/MainLayout'
import {
  routerHemodialysis,
  routerHemodialysisTurns
} from '@/pages/hemodialysis/router'
import { routerImagenology } from '@/pages/imagen/router'
import { routerPharmacy } from '@/pages/pharmacy/router'
import { routerCashier } from '@/pages/cashier/router'
import { routerLaboratory } from '@/pages/laboratory/router'
import { routerMedicalHistory } from '@/pages/medical_history/router'
import { AccesDenied } from '@/pages/AccesDenied'
import { routerEmergency } from '@/pages/emergency'

const Home = lazy(() => import('@/pages/Home'))
const NotFound = lazy(() => import('@/pages/NotFound'))
const Calendar = lazy(() => import('@/pages/calendar/Calendar'))
const Reschedule = lazy(() => import('@/pages/calendar/Reschedule'))
const CreditSale = lazy(() => import('@/pages/credit_sale'))
const CreditSaleDetail = lazy(() =>
  import('@/pages/credit_sale/CreditSaleDetail')
)
const SaleLaboratory = lazy(() =>
  import('@/pages/medical_history/SaleLaboratory')
)
const MedicalHistorySales = lazy(() => import('@/pages/medical_history_sales'))
const DayOff = lazy(() => import('@/pages/profile/views/DayOff'))
const DoctorSpecialty = lazy(() =>
  import('@/pages/profile/views/DoctorSpecialty')
)
const WorkingHours = lazy(() => import('@/pages/profile/views/WorkingHours'))
const UserSettings = lazy(() => import('@/pages/profile/views/UserSettings'))
const Register = lazy(() => import('@/pages/register/Register'))
const Report = lazy(() => import('@/pages/report/Report'))
const ReportPrint = lazy(() => import('@/pages/report/ReportPrint'))
const Samples = lazy(() => import('@/pages/samples'))
const Schedule = lazy(() => import('@/pages/schedule/Schedule'))
const VitalSigns = lazy(() => import('@/pages/vital_signs'))

export function DashboardRoutes() {
  return (
    <Routes>
      <Route path={PrivateRouteList.HOME} element={<MainLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<LoadingPage />}>
              <Home />
            </Suspense>
          }
        />

        <Route
          path={PrivateRouteList.REGISTER}
          element={
            <Suspense fallback={<LoadingPage />}>
              <Register />
            </Suspense>
          }
        />

        <Route
          path={PrivateRouteList.SCHEDULE}
          element={
            <Suspense fallback={<LoadingPage />}>
              <Schedule />
            </Suspense>
          }
        />

        <Route
          path={PrivateRouteList.CASHIER.CALENDAR}
          element={
            <Suspense fallback={<LoadingPage />}>
              <Calendar />
            </Suspense>
          }
        />
        <Route
          path={PrivateRouteList.CASHIER.RESCHEDULE}
          element={
            <Suspense fallback={<LoadingPage />}>
              <Reschedule />
            </Suspense>
          }
        />

        <Route
          path={PrivateRouteList.DAY_OFF}
          element={
            <Suspense fallback={<LoadingPage />}>
              <DayOff />
            </Suspense>
          }
        />

        <Route
          path={PrivateRouteList.PROFILE}
          element={
            <Suspense fallback={<LoadingPage />}>
              <WorkingHours />
            </Suspense>
          }
        />

        <Route
          path={PrivateRouteList.CASHIER.REPORT}
          element={
            <Suspense fallback={<LoadingPage />}>
              <Report />
            </Suspense>
          }
        />

        <Route
          path={PrivateRouteList.CASHIER.REPORT_PRINT}
          element={
            <Suspense fallback={<LoadingPage />}>
              <ReportPrint />
            </Suspense>
          }
        />

        <Route
          path={PrivateRouteList.SAMPLES}
          element={
            <Suspense fallback={<LoadingPage />}>
              <Samples />
            </Suspense>
          }
        />

        <Route
          path={PrivateRouteList.VITAL_SIGNS}
          element={
            <Suspense fallback={<LoadingPage />}>
              <VitalSigns />
            </Suspense>
          }
        />

        <Route
          path={PrivateRouteList.MEDICAL_HISTORY.SPECIALTY}
          element={
            <Suspense fallback={<LoadingPage />}>
              <DoctorSpecialty />
            </Suspense>
          }
        />
        <Route
          path={PrivateRouteList.SALE_LABORATORY}
          element={
            <Suspense fallback={<LoadingPage />}>
              <SaleLaboratory />
            </Suspense>
          }
        />
        <Route
          path={PrivateRouteList.MEDICAL_HISTORY.MEDICAL_HISTORY_SALES}
          element={
            <Suspense fallback={<LoadingPage />}>
              <MedicalHistorySales />
            </Suspense>
          }
        />
        <Route
          path={PrivateRouteList.SETTINGS}
          element={
            <Suspense fallback={<LoadingPage />}>
              <UserSettings />
            </Suspense>
          }
        />

        <Route
          path={PrivateRouteList.CASHIER.CREDIT_SALE}
          element={
            <Suspense fallback={<LoadingPage />}>
              <CreditSale />
            </Suspense>
          }
        />
        <Route
          path={PrivateRouteList.CASHIER.CREDIT_SALE_DETAIL}
          element={
            <Suspense fallback={<LoadingPage />}>
              <CreditSaleDetail />
            </Suspense>
          }
        />
        {routerCashier}
        {routerImagenology}
        {routerHemodialysis}
        {routerHemodialysisTurns}
        {routerPharmacy}
        {routerLaboratory}
        {routerMedicalHistory}
        {routerEmergency}

        <Route
          path={PrivateRouteList.ACCCESS_DENIED}
          element={
            <Suspense fallback={<LoadingPage />}>
              <AccesDenied />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<LoadingPage />}>
              <NotFound />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  )
}
