export const column1 = [
  'HEMATOLOGÍA',
  'HEMOSTASIA - COAGULOGRAMA',
  'PERFIL DE HIERRO',
  'QUIMICA METABÓLICA',
  'PERFIL LIPIDICO',
  'PERFIL HEPÁTICO'
]
export const column2 = [
  'PERFIL CELIACO',
  'ELECTROLITOS',
  'GASOMETRIA',
  'PERFIL OSEO',
  'ENFERMEDADES INFECCIOSAS',
  'PERFIL CARDIOPULMONAR'
]
export const column3 = [
  'SEROLOGIA',
  'INMUNOGLOBULINAS',
  'HEPATITIS AUTOINMUNE',
  'MARCADORES TUMORALES',
  'ENDOCRINOLOGÍA'
]
export const column4 = [
  'UROANÁLISIS',
  'PARASITOLOGIA/ EXAM. EN HECES',
  'MICROBIOLOGIA',
  'LIQUIDOS ORGANICOS',
  'EXAMEN DE EMBARAZO',
  'NUEVOS EXAMENES'
]
