import React from 'react'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid2'
import Paper from '@mui/material/Paper'
import FaceIcon from '@mui/icons-material/Face'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Alert from '@mui/material/Alert'

import moment from 'moment'
import { calculateAge, format_ci_ext, format_name } from '@/helpers'
import { genderType } from '@/constants'

function TableRowCustom({ label, value }) {
  return (
    <TableRow hover>
      <TableCell align="right" sx={{ width: '30%' }}>
        <Typography variant="caption">{label}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography variant="body2">{value}</Typography>
      </TableCell>
    </TableRow>
  )
}

function TableRowMDRD({ label, mdrd }) {
  return (
    <>
      <TableRow hover>
        <TableCell align="right" sx={{ width: '30%' }}>
          <Typography variant="caption">{label}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body2" fontWeight="bold">
            {Number(mdrd?.result || 0).toFixed(2)} mL/min/1.73m²
          </Typography>
          <Typography variant="caption">
            {mdrd?.updatedAt ? moment(mdrd?.updatedAt).format('LLL') : ''}
          </Typography>
        </TableCell>
      </TableRow>
      {mdrd?.message?.length > 0 && (
        <TableRow>
          <TableCell colSpan={2} sx={{ p: 0, m: 0 }}>
            {mdrd?.message?.map(message => (
              <Alert
                key={message}
                variant="standard"
                severity="error"
                sx={{ p: 0, my: 1 / 2, mx: 0 }}
              >
                <Typography variant="caption">{message}</Typography>
              </Alert>
            ))}
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

const patientTextAge = birthdate => {
  if (birthdate) {
    const text = moment(new Date(birthdate)).format('DD/MM/YYYY')
    const age = calculateAge(birthdate)
    return `${text} - ${age} años`
  }
  return 'No registrado'
}

function CardPatient({ patient, mdrd }) {
  const [more, setMore] = React.useState(false)
  const noRegister = 'No registrado'

  return (
    <Paper variant="outlined">
      <Grid container>
        <Grid size={{ xs: 12 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="right">
                  <FaceIcon sx={{ fontSize: 50 }} color="disabled" />
                </TableCell>
                <TableCell>
                  {patient ? (
                    <>
                      <Typography variant="body2">
                        {format_name(patient)}
                      </Typography>
                      <Typography variant="caption" color="disabled">
                        {`Edad: ${patientTextAge(patient?.birthdate || false)}`}
                      </Typography>
                    </>
                  ) : (
                    'Seleccione un paciente'
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRowCustom
                label="CI"
                value={format_ci_ext(patient) || noRegister}
              />
              <TableRowCustom
                label="Hemodiálisis"
                value={patient?.hemodialysis ? 'SI' : 'NO'}
              />
              <TableRowMDRD label="MDRD" mdrd={mdrd} />

              {more ? (
                <>
                  <TableRowCustom
                    label="Email"
                    value={patient?.email || noRegister}
                  />
                  <TableRowCustom
                    label="Cel"
                    value={patient?.cellphone || noRegister}
                  />
                  <TableRowCustom
                    label="Dirección"
                    value={patient?.address || noRegister}
                  />
                  <TableRowCustom
                    label="País"
                    value={patient?.country || noRegister}
                  />
                  <TableRowCustom
                    label="Genero"
                    value={genderType.traslate(patient?.gender)}
                  />
                  <TableRow
                    hover
                    onClick={() => setMore(false)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell colSpan={2}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <RemoveIcon fontSize="small" />
                        Mostrar menos
                      </div>
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <TableRow
                  hover
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setMore(true)}
                >
                  <TableCell colSpan={2}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <AddIcon fontSize="small" />
                      Mostrar mas
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default CardPatient
