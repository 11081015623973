import { useQuery } from '@tanstack/react-query'

import Stack from '@mui/material/Stack'

import { internService, medicalService } from '@/services'
import { AutocompleteICD, AutocompleteRecord, AutocompleteUnderlying, IsSmoke, QueryActions, SelectAutoSave } from '@/components'
import { useColors } from '@/hooks'
import { bloodTypes } from '@/constants'

export const CardMedicalHistory = ({ admissionId, patient_id }) => {
  const { setAction, color } = useColors()

  const queryAdmission = useQuery({
    queryKey: ['get-medical-history', { admissionId }],
    queryFn: () => internService.getMedicalHistoryByAdmissionFormId(admissionId),
    enabled: !!admissionId,
    placeholderData: previousData => previousData || null
  })

  const handleSubmitMedicalHistory = data => {
    medicalService
      .putUpdateMedicalHistory({ patient_id, ...data })
      .then(() => {
        queryAdmission.refetch()
        setAction('success')
      })
      .catch(() => {
        setAction('error')
      })
  }

  return (
    <Stack spacing={2}>
      <QueryActions query={queryAdmission} />

      <AutocompleteICD
        freeSolo
        label="Antecedente familiares (ICD / CIE)"
        data={queryAdmission?.data?.family_record}
        onChange={value => {
          handleSubmitMedicalHistory({
            family_record: value
          })
        }}
        color={color}
        helperText="Presione 'Enter' para guardar"
      />

      <AutocompleteRecord
        freeSolo
        label="Antecedentes quirurgicos"
        data={queryAdmission?.data?.surgical_record}
        onChange={value => {
          handleSubmitMedicalHistory({
            surgical_record: value
          })
        }}
        color={color}
        helperText="Presione 'Enter' para guardar"
      />

      <AutocompleteRecord
        freeSolo
        label="Antecedentes sociales"
        data={queryAdmission?.data?.social_record}
        onChange={value =>
          handleSubmitMedicalHistory({
            social_record: value
          })
        }
        color={color}
        helperText="Presione 'Enter' para guardar"
      />

      <AutocompleteICD
        freeSolo
        label="Alergias (ICD / CIE)"
        data={queryAdmission?.data?.allergies}
        onChange={value =>
          handleSubmitMedicalHistory({
            allergies: value
          })
        }
        color={color}
        helperText="Presione 'Enter' para guardar"
      />

      <AutocompleteUnderlying
        label="Enfermedades de base / antecedentes medicos (ICD / CIE)"
        data={queryAdmission?.data?.record_underlying_disease}
        medical_history_id={queryAdmission?.data?.id}
        onChange={value => {
          const refactor = value.map(item => ({
            id: item.id,
            disease: item.disease,
            medical_history_id: item.medical_history_id,
            diagnostic_id: item.diagnostic_id,
            medical_history_detail_id: item.medical_history_detail_id
          }))
          handleSubmitMedicalHistory({ record_underlying_disease: refactor })
        }}
        color={color}
      />

      <IsSmoke
        onChange={(text, smoke) => {
          handleSubmitMedicalHistory({
            smokes: text,
            is_smokes: smoke
          })
        }}
        smokes={queryAdmission?.data?.smokes}
        is_smoke={queryAdmission?.data?.is_smokes}
        color={color}
      />

      <SelectAutoSave
        data={queryAdmission?.data?.blood_type}
        label="Tipo de sangre"
        onChange={value => handleSubmitMedicalHistory({ blood_type: value })}
        options={bloodTypes}
        color={color}
      />
    </Stack>
  )
}
