import store from '@/store'

export const authHeader = () => {
  const {
    auth: { token, loggedIn }
  } = store.getState()

  if (loggedIn) {
    return `Bearer ${token.token}`
  }
  return ''
}
