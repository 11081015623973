import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = []

export const cartSlice = createSlice({
  name: 'shoping-cart',
  initialState: INITIAL_STATE,
  reducers: {
    actionAddShoppingCart: (state, action) => {
      return action.payload
    },

    actionPushShoppingCart: (state, action) => {
      return [...state, action.payload]
    },

    actionPushShoppingLab: (state, action) => {
      let exist = false
      const products = state.map(prod => {
        if (prod.product_id === action.payload.product_id) {
          exist = true
          return action.payload
        }
        return prod
      })

      if (exist) {
        return products
      }
      return [...state, action.payload]
    },

    actionDeleteShoppingCartID: (state, action) => {
      return state.filter(prod => prod.product_id !== action.payload)
    },

    actionDeleteShoppingCategory: (state, action) => {
      return state.filter(prod => prod.category !== action.payload)
    },

    actionDeleteShoppingCart: (state, action) => {
      return state.filter((prod, index) => index !== action.payload)
    },

    actionClearShoppingCart: state => {
      return INITIAL_STATE
    },

    actionUpdateShoppingCart: (state, action) => {
      const response = state.map((prod, index) =>
        index === action.payload.index ? action.payload.product : prod
      )
      return response
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  actionAddShoppingCart,
  actionClearShoppingCart,
  actionDeleteShoppingCart,
  actionDeleteShoppingCartID,
  actionDeleteShoppingCategory,
  actionPushShoppingCart,
  actionPushShoppingLab,
  actionUpdateShoppingCart
} = cartSlice.actions

export default cartSlice.reducer
