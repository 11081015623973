import { Suspense, lazy } from 'react'
import { Route } from 'react-router-dom'

import LaboratoryLayout from './index'
import { PrivateRouteList } from '@/routers/routerList'
import LoadingPage from '../Loading'

const PageSampleResult = lazy(() =>
  import('@/components/laboratory/tab_sample_result').then(module => ({
    default: module.PageSampleResult
  }))
)
const PageLaboratories = lazy(() =>
  import('@/components/laboratory/tab_laboratories').then(module => ({
    default: module.PageLaboratories
  }))
)
const PageReferenceValue = lazy(() =>
  import('@/components/laboratory/tab_reference_value')
)
const PageSubLaboratory = lazy(() =>
  import('@/components/laboratory/tab_sub_laboratories')
)
const PageAnalysis = lazy(() => import('@/components/laboratory/tab_category'))

const TabPageWhatsApp = lazy(() =>
  import('@/components/laboratory/tab_whatsapp').then(module => ({
    default: module.TabPageWhatsApp
  }))
)

export const routerLaboratory = (
  <Route path={PrivateRouteList.LABORATORY.ROOT} element={<LaboratoryLayout />}>
    <Route
      index
      element={
        <Suspense fallback={<LoadingPage />}>
          <PageSampleResult />
        </Suspense>
      }
    />
    <Route
      path={PrivateRouteList.LABORATORY.HISTORY}
      element={
        <Suspense fallback={<LoadingPage />}>
          <PageLaboratories />
        </Suspense>
      }
    />
    <Route
      path={PrivateRouteList.LABORATORY.REFERENCE_VALUE}
      element={
        <Suspense fallback={<LoadingPage />}>
          <PageReferenceValue />
        </Suspense>
      }
    />
    <Route
      path={PrivateRouteList.LABORATORY.SUB_LABORATORY}
      element={
        <Suspense fallback={<LoadingPage />}>
          <PageSubLaboratory />
        </Suspense>
      }
    />
    <Route
      path={PrivateRouteList.LABORATORY.ANALYSIS}
      element={
        <Suspense fallback={<LoadingPage />}>
          <PageAnalysis />
        </Suspense>
      }
    />
    <Route
      path={PrivateRouteList.LABORATORY.WHATSAPP}
      element={
        <Suspense fallback={<LoadingPage />}>
          <TabPageWhatsApp />
        </Suspense>
      }
    />
  </Route>
)
