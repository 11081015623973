import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  drawerLeft: true,
  drawerRight: true
}

export const configSlice = createSlice({
  name: 'config',
  initialState: JSON.parse(localStorage.getItem('config')) ?? INITIAL_STATE,
  reducers: {
    updateDrawerLeft: (state, action) => {
      const value = action.payload
      localStorage.setItem(
        'config',
        JSON.stringify({ ...state, drawerLeft: value })
      )
      return { ...state, drawerLeft: value }
    },
    updateDrawerRight: (state, action) => {
      const value = action.payload
      localStorage.setItem(
        'config',
        JSON.stringify({ ...state, drawerRight: value })
      )
      return { ...state, drawerRight: value }
    }
  }
})

// Action creators are generated for each case reducer function
export const { updateDrawerLeft, updateDrawerRight } = configSlice.actions

export default configSlice.reducer
