import React from 'react'

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { Box } from '@mui/system'

import { useSelector } from 'react-redux'

function LaboratoriesAutocomplete({
  value,
  onChangeCallback,
  requiredA = true
}) {
  const products = useSelector(state => state.reducerProducts)
  return (
    <Autocomplete
      noOptionsText="El producto no está registrado"
      value={value}
      filterOptions={createFilterOptions({
        matchFrom: 'all',
        stringify: prod => prod.name + prod.description
      })}
      options={products}
      autoHighlight
      groupBy={prod => prod.category.name}
      getOptionDisabled={prod =>
        !prod.state || prod.category.name !== 'Laboratorio'
      }
      getOptionLabel={product => product.name}
      renderOption={(props, product) => {
        if (product.category.name === 'Laboratorio') {
          return (
            <Box component="li" {...props}>
              {product.name}
            </Box>
          )
        }
        return null
      }}
      renderInput={params => (
        <TextField
          required={requiredA}
          {...params}
          autoComplete="new-text"
          label="Buscar Laboratorios"
        />
      )}
      onChange={(e, value) => {
        onChangeCallback(value)
      }}
    />
  )
}

export default LaboratoriesAutocomplete
