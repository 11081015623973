import * as yup from 'yup'

export const medicalOrderSchema = yup.object().shape({
  name: yup.string().required('Campo requerido'),
  description: yup.string().nullable(),
  categoryMedicalOrder: yup.object().required('Campo requerido'),
  typeMedicalOrder: yup.string().required('Campo requerido'),

  frequency: yup.object().nullable(),
  firstAplication: yup.date().nullable(),
  endAplication: yup.date().nullable(),

  medicine: yup.object().nullable(),
  surgery: yup.object().nullable(),
  vitalSign: yup.object().nullable(),
  physicalExamType: yup.string().nullable(),
  products: yup.array()
})

export const INITIAL_MEDICAL_ORDER = {
  name: '',
  description: '',
  typeMedicalOrder: '',
  categoryMedicalOrder: null,
  frequency: null,
  firstAplication: null,
  endAplication: null,
  medicine: null,
  surgery: null,
  vitalSign: null,
  physicalExamType: '',
  products: []
}
