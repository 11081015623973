import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = []

export const meetSlice = createSlice({
  name: 'meets',
  initialState: INITIAL_STATE,
  reducers: {
    actionPostMeet: (state, action) => {
      return action.payload
    },

    actionPushMeet: (state, action) => {
      let exist = false
      state.forEach(elem => {
        if (
          elem.extendedProps.data.id === action.payload.extendedProps.data.id
        ) {
          exist = true
        }
      })

      if (!exist) return [...state, action.payload]
      exist = false

      return state
    },

    actionClearMeet: () => {
      return INITIAL_STATE
    },

    actionUpdateMeet: (state, action) => {
      let data = state
      if (
        data.filter(
          it =>
            it.extendedProps.data.id === action.payload.extendedProps.data.id
        ).length === 0
      ) {
        data.push(action.payload)
      }
      data = data.map(it => {
        if (it.extendedProps.data.id === action.payload.extendedProps.data.id) {
          it = action.payload
        }
        return it
      })
      return data
    },

    actionDeleteMeet: (state, action) => {
      return state.filter(elem => elem.extendedProps.data.id !== action.payload)
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  actionClearMeet,
  actionDeleteMeet,
  actionPostMeet,
  actionPushMeet,
  actionUpdateMeet
} = meetSlice.actions

export default meetSlice.reducer
