import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useQuery } from '@tanstack/react-query'

import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid2'
import Button from '@mui/material/Button'

import { INITIAL_HOSPITALIZATION, initialHospitalizationSchema } from '../schemas'
import { internInitalService } from '@/services'
import { AutocompleteForm, DialogCustom } from '@/components'
import { TriageLevelRender } from './TriageLevelRender'

export const ModalTriageLevel = ({ open, setOpen, handleEditTriageLevel }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(initialHospitalizationSchema),
    defaultValues: INITIAL_HOSPITALIZATION
  })

  const queryTriageLevelels = useQuery({
    queryKey: ['get-triage-levels'],
    queryFn: () => internInitalService.getTriageLevels(),
    enabled: !!open,
    placeholderData: previousData => previousData || []
  })

  const onSubmit = async data => {
    await handleEditTriageLevel(data)
    clear()
  }

  const clear = () => {
    reset()
    setOpen(false)
  }

  return (
    <Stack>
      {open && (
        <DialogCustom title="Prioridad" open={open} handleClose={clear} maxWidth="xs">
          <Grid container component="form" onSubmit={handleSubmit(onSubmit)} onReset={clear} spacing={2} sx={{ p: 2 }}>
            <Grid size={{ xs: 12 }}>
              <AutocompleteForm
                control={control}
                name="triageLevel"
                label="Prioridad"
                defaultValue={null}
                options={queryTriageLevelels.data}
                error={!!errors?.triageLevel}
                helperText={errors?.triageLevel?.message}
                renderLabel={option => option.name}
                renderOption={option => <TriageLevelRender {...option} />}
                isOptionEqualToValue={(opt, val) => opt.id === val.id}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Button type="submit">Guardar</Button>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Button type="reset" variant="outlined">
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogCustom>
      )}
    </Stack>
  )
}
