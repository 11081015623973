import { useEffect, useState } from 'react'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

import SearchIcon from '@mui/icons-material/Search'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'

import { format_name_ci } from '@/helpers'
import { userService } from '@/services'

function SearchPatientAutocomplete({ value, onChangeCallBack, readOnly }) {
  const [patients, setPatients] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== '' && search && search.length > 2) {
        setLoading(true)
        setPatients([])
        userService
          .searchUser({ text: search })
          .then(data => {
            setPatients(data)
          })
          .finally(() => setLoading(false))
      } else clearTimeout(delayDebounceFn)
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    if (!value) setPatients([])
  }, [value])

  return (
    <Autocomplete
      disabled={readOnly}
      noOptionsText={'El paciente no está registrado'}
      loading={loading}
      loadingText="Cargando..."
      value={value}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={patients}
      autoHighlight
      getOptionLabel={patient => format_name_ci(patient)}
      renderOption={(props, option) => {
        const matches = match(format_name_ci(option), search, {
          insideWords: true
        })
        const parts = parse(format_name_ci(option), matches)
        return (
          <li {...props}>
            <Box sx={{ width: '100%', wordWrap: 'break-word' }}>
              {parts.map((part, index) => (
                <Box
                  key={index}
                  component="span"
                  sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                >
                  {part.text}
                </Box>
              ))}
            </Box>
          </li>
        )
      }}
      filterOptions={() => patients}
      renderInput={params => (
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <TextField
            {...params}
            label="Buscar paciente"
            onChange={e => {
              setPatients([])
              if (e.target.value !== '') setSearch(e.target.value)
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon sx={{ color: 'gray' }} />,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress
                      color="primary"
                      size={20}
                      sx={{ mr: 3 }}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        </div>
      )}
      onChange={(_, select) => {
        onChangeCallBack(select)
        setPatients([])
      }}
    />
  )
}

export default SearchPatientAutocomplete
