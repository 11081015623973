import { useMediaQuery, useTheme } from '@mui/material'

export const useResponsive = (query, key, start, end) => {
  const theme = useTheme()
  const up = useMediaQuery(theme.breakpoints.up(key))
  const down = useMediaQuery(theme.breakpoints.down(key))
  const between = useMediaQuery(
    theme.breakpoints.between(start ?? 'xs', end ?? 'sm')
  )
  const only = useMediaQuery(theme.breakpoints.only(key))

  if (query === 'up') return up
  if (query === 'down') return down
  if (query === 'between' && start && end) return between
  if (query === 'only') return only
}
