import { useEffect, useState } from 'react'
import { Outlet, useSearchParams } from 'react-router-dom'

import Grid from '@mui/material/Grid2'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import ConfirmationDialog from '@/components/ConfirmationDialog'
import DialogImagingSale from '@/components/medical_history/tab_history/DialogImagingSale'
import LaboratoriesDialog from '@/components/medical_history/tab_history/laboratories/LaboratoriesDialog'
import ReferPatient from '@/components/medical_history/tab_history/medical_consultation/Referpatient'
import LaboratoryDialog from '@/components/medical_history/tab_history/sale_laboratories/LaboratoryDialog'
import AssigneeList from '@/components/medical_history/tab_patient/AssigneeList'
import CardPatient from '@/components/medical_history/tab_patient/CardPatient'
import FormHistory from '@/components/medical_history/tab_patient/FormHistory'
import { MedicalHistoryContext } from '@/contexts/MedicalHistoryContext'

import { PrivateRouteList } from '@/routers/routerList'
import SearchPatientAutocomplete from '@/components/SearchPatientAutocomplete'
import { medicalService, userService } from '@/services'
import HistoryLayout from '@/layouts/HistoryLayout'
import {
  useAbortController,
  useGetGeneralRecomendations,
  useResponsive,
  useSearchParamsValues
} from '@/hooks'
import RightDrawer, { drawerWidthHistory } from './components/RightDrawer'
import HistoryImagingDialog from '../imagen/component/HistoryImagingDialog'
import { patientType, searchParamNames } from '@/constants'
import { useDispatch, useSelector } from 'react-redux'
import { updateDrawerRight } from '@/store/config'

const rutas = [
  {
    to: PrivateRouteList.MEDICAL_HISTORY.ROOT,
    label: 'Todos'
  },
  {
    to: PrivateRouteList.MEDICAL_HISTORY.HISTORY,
    label: 'Historial'
  },
  {
    to: PrivateRouteList.MEDICAL_HISTORY.COMPARATIVE_LABORATORIES,
    label: 'Laboratorios'
  },
  {
    to: PrivateRouteList.MEDICAL_HISTORY.IMAGENOLOGY,
    label: 'Estudios complementarios'
  },
  {
    to: PrivateRouteList.MEDICAL_HISTORY.CERTIFICATE,
    label: 'Certificado médico'
  },
  {
    to: PrivateRouteList.MEDICAL_HISTORY.LABORATORY,
    label: 'Historial de Laboratorios'
  }
]

function MedicalHistory(props) {
  const { window } = props
  const container =
    window !== undefined ? () => window().document.body : undefined
  const [patient, setPatient] = useState(null)
  const [asignedDoctor, setAsignedDoctor] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const matches = useResponsive('up', 'md')
  const { setParams } = useSearchParamsValues()
  const { abortController } = useAbortController()
  const { generalRecomendations, setGeneralRecomendations } =
    useGetGeneralRecomendations()
  const { drawerRight } = useSelector(state => state.config)
  const dispatch = useDispatch()

  const [dialog, setDialog] = useState({
    show: false,
    laboratory: []
  })
  const [confirmation, setConfirmation] = useState({
    show: false,
    message: '',
    title: '',
    approveMedicalHistory: null
  })
  const [sale, setSale] = useState({
    show: false,
    id: '',
    diagnostic: ''
  })
  const [refer, setRefer] = useState({
    show: false,
    diagnostic: ''
  })
  const [saleImaging, setSaleImaging] = useState({
    show: false,
    item: ''
  })
  const [imagingHistory, setImagingHistory] = useState({
    show: false,
    medical_detail_id: ''
  })

  const [historyData, setHistoryData] = useState({
    id: '',
    family_record: [],
    surgical_record: [],
    medicines: [],
    record_underlying_disease: [],
    social_record: [],
    allergies: [],
    blood_type: '',
    patient_type: patientType.EXTERNO,
    smokes: '',
    is_smokes: false
  })

  const clearForm = () => {
    setSearchParams(undefined)
    setAsignedDoctor([])
    setHistoryData({
      id: '',
      family_record: [],
      surgical_record: [],
      medicines: [],
      record_underlying_disease: [],
      social_record: [],
      allergies: [],
      blood_type: '',
      patient_type: patientType.EXTERNO,
      smokes: '',
      is_smokes: false,
      mdrd: null
    })
  }

  useEffect(() => {
    const patientId = searchParams.get(searchParamNames.patientId)
    if (patientId) {
      userService
        .getUser(patientId, abortController)
        .then(data => setPatient(data))
    }
  }, [])

  useEffect(() => {
    const patientId = searchParams.get(searchParamNames.patientId)
    if (patientId) {
      medicalService
        .getMedicalHistoryOneByPatientId(patientId, abortController)
        .then(data => {
          setAsignedDoctor(data?.assigned_doctors || [])
          setHistoryData(data)
        })
    }
  }, [searchParams.get(searchParamNames.patientId)])

  const handleDrawerToggle = () => {
    dispatch(updateDrawerRight(!drawerRight))
  }

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    dispatch(updateDrawerRight(open))
  }

  return (
    <MedicalHistoryContext.Provider
      value={{
        historyData,
        setHistoryData,
        setDialog,
        setSale,
        setRefer,
        setSaleImaging,
        setImagingHistory,
        generalRecomendations,
        setGeneralRecomendations
      }}
    >
      <Box
        aria-label="folders"
        sx={{
          width: {
            md: drawerRight ? `calc(100% - ${drawerWidthHistory}px)` : undefined
          }
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          py={1}
        >
          <Grid size={{ xs: 11, md: 8 }}>
            <SearchPatientAutocomplete
              value={patient}
              id={searchParams.get(searchParamNames.patientId)}
              onChangeCallBack={value => {
                setPatient(value)
                if (value) {
                  setParams([
                    { key: searchParamNames.patientId, value: value.id }
                  ])
                } else {
                  clearForm()
                }
              }}
            />
          </Grid>
          <Grid>
            <IconButton onClick={handleDrawerToggle}>
              {drawerRight ? <ArrowForwardIosIcon /> : <ArrowBackIosNewIcon />}
            </IconButton>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <HistoryLayout rutas={rutas} />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Outlet />
          </Grid>
        </Grid>

        <RightDrawer
          container={container}
          handleDrawerToggle={handleDrawerToggle}
          matches={matches}
          toggleDrawer={toggleDrawer}
          open={drawerRight}
        >
          <Grid container direction="column" spacing={2}>
            <Grid>
              <CardPatient patient={patient} mdrd={historyData.mdrd} />
            </Grid>
            <Grid>
              <Grid
                container
                direction="row"
                spacing={2}
                sx={{ height: 'calc(100vh - 320px)', overflow: 'auto' }}
              >
                <Grid size={{ xs: 12 }}>
                  <AssigneeList
                    patient={patient}
                    asignedDoctor={asignedDoctor}
                    setAsignedDoctor={setAsignedDoctor}
                  />
                </Grid>
                <Grid size={{ xs: 12 }}>
                  {patient && (
                    <FormHistory
                      historyData={historyData}
                      setHistoryData={setHistoryData}
                      patient={patient}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </RightDrawer>
      </Box>

      {refer.show && (
        <ReferPatient
          open={refer.show}
          handleClose={() => setRefer({ ...refer, show: false })}
          diagnostic={refer.diagnostic}
          presc={refer.prescription}
          patient={patient}
        />
      )}
      {dialog.show && (
        <LaboratoriesDialog
          open={dialog.show}
          laboratory={dialog.laboratory}
          handleClose={() => setDialog({ ...dialog, show: false })}
          medical_history_detail_id={dialog.medical_history_detail_id}
        />
      )}
      {confirmation.show && (
        <ConfirmationDialog
          open={confirmation.show}
          message={confirmation.message}
          title={confirmation.title}
          handleClose={() => setConfirmation({ ...confirmation, show: false })}
          handleOk={confirmation.approveMedicalHistory}
        />
      )}
      {sale.show && (
        <LaboratoryDialog
          open={sale.show}
          handleClose={() => setSale({ ...sale, show: false })}
          patient={patient}
          medical_history_detail_id={sale.id}
          diagn={sale.diagnostic}
        />
      )}
      {saleImaging.show && (
        <DialogImagingSale
          open={saleImaging.show}
          handleClose={() => setSaleImaging({ ...saleImaging, show: false })}
          medical_history_detail_id={saleImaging.item.id}
          medicalHistory={saleImaging.item}
          patient={patient}
        />
      )}
      {imagingHistory.show && (
        <HistoryImagingDialog
          open={imagingHistory.show}
          handleClose={() =>
            setImagingHistory({ ...imagingHistory, show: false })
          }
          medical_detail_id={imagingHistory.medical_detail_id}
        />
      )}
    </MedicalHistoryContext.Provider>
  )
}

export default MedicalHistory
