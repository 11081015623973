import axios from '@/boot/axios'

const url_history_detail = 'v1/medical-history-detail'
const url_history = 'v1/medical-history'
const url_physical_exam = 'v1/physical-exam'
const url_vital_signs = 'v1/vital-signs'

export const medicalService = {
  getMedicalHistoryOneByPatientId: (patient_id, abortController) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url_history}/one/${patient_id}`, {
          signal: abortController?.signal || null
        })
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },

  putUpdateMedicalHistory: ({
    allergies,
    family_record,
    surgical_record,
    social_record,
    medicines,
    smokes,
    is_smokes,
    record_underlying_disease,
    blood_type,
    state,
    patient_type,
    patient_id
  }) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${url_history}/${patient_id}`, {
          allergies,
          family_record,
          surgical_record,
          social_record,
          medicines,
          smokes,
          is_smokes,
          record_underlying_disease,
          blood_type,
          state,
          patient_type,
          patient_id
        })
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },

  putMedicalHistoryDetail: (id, model) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${url_history_detail}/${id}`, model)
        .then(response => resolve(response))
        .catch(e => reject(e))
    })
  },

  putVitalSigns: (id, model) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${url_vital_signs}/${id}`, model)
        .then(response => resolve(response))
        .catch(e => reject(e))
    })
  },

  putPhysicalExam: (id, model) => {
    return new Promise((resolve, reject) =>
      axios
        .put(`${url_physical_exam}/${id}`, model)
        .then(response => resolve(response))
        .catch(e => reject(e))
    )
  },

  aproveDoctor: id => {
    const model = {
      approve: true
    }
    return new Promise((resolve, reject) =>
      axios
        .put(`${url_history_detail}/${id}`, model)
        .then(response => resolve(response))
        .catch(e => reject(e))
    )
  },

  syncRecordLogs: data => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${url_history}/add-medical-logs`, data)
        .then(response => resolve(response.data))
        .catch(e => reject(e))
    })
  },

  getMedicalHistories: id => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url_history_detail}/patient/${id}`)
        .then(response => resolve(response.data))
        .catch(e => reject(e))
    })
  },

  getAllMedicalCestificates: id => {
    return new Promise((resolve, reject) => {
      axios
        .get('v1/medical-certificate/patient/' + id)
        .then(response => resolve(response.data))
        .catch(e => reject(e))
    })
  },

  postMedicalCestificates: ({
    name_doctor,
    professional_registration,
    place_and_date,
    description,
    medical_history_id,
    doctor_id
  }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/medical-certificate', {
          name_doctor,
          professional_registration,
          place_and_date,
          description,
          medical_history_id,
          doctor_id
        })
        .then(response => resolve(response.data))
        .catch(e => reject(e))
    })
  },

  putMedicalCestificates: (
    certificateId,
    { name_doctor, professional_registration, place_and_date, description, medical_history_id, doctor_id }
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .put('v1/medical-certificate/' + certificateId, {
          name_doctor,
          professional_registration,
          place_and_date,
          description,
          medical_history_id,
          doctor_id
        })
        .then(response => resolve(response.data))
        .catch(e => reject(e))
    })
  }
}
