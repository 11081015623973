import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import NotificationsIcon from '@mui/icons-material/Notifications'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import ListSubheader from '@mui/material/ListSubheader'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandMore from '@mui/icons-material/ExpandMore'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from '@mui/material/Button'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import { clearAll, deleteNotify, selectNotify } from '@/store/notifications'
import { DialogCustom } from './DialogCustom'
import { variantName, variants } from '@/providers/Notify'

const Notify = ({ item, handleDelete }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <ListItem
        divider={false}
        secondaryAction={
          <Stack direction="row" spacing={2}>
            <IconButton onClick={handleDelete} color="error">
              <DeleteIcon />
            </IconButton>
            {typeof item.message !== 'string' && (
              <IconButton onClick={() => setOpen(!open)}>
                <ExpandMore />
              </IconButton>
            )}
          </Stack>
        }
      >
        <Alert severity={item.variant} sx={{ width: '100%' }}>
          <Typography variant="subtitle2">
            {item.id}
            {' -  '}
            {typeof item.message === 'string'
              ? item.message
              : variantName[item.variant]}
          </Typography>
        </Alert>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {Array.isArray(item.message?.message) &&
            item.message?.message.map(elem => (
              <ListItem key={elem} divider={false}>
                <ListItemText
                  primary={elem}
                  primaryTypographyProps={{ variant: 'caption' }}
                  secondary={`${item?.message?.statusCode || ''} ${
                    item?.message?.error || ''
                  }`.trim()}
                />
              </ListItem>
            ))}
          {typeof item?.message?.message === 'string' && (
            <ListItem divider={false}>
              <ListItemText
                primary={item.message.message}
                primaryTypographyProps={{ variant: 'caption' }}
                secondary={`${item?.message?.statusCode || ''} ${
                  item?.message?.error || ''
                }`.trim()}
              />
            </ListItem>
          )}
        </List>
      </Collapse>
    </>
  )
}
const NotificationButton = () => {
  const { notifyList } = useSelector(selectNotify)
  const [open, setOpen] = useState(false)
  const [filtered, setFiltered] = useState(notifyList)
  const dispatch = useDispatch()
  const [state, setState] = useState({
    success: true,
    error: true,
    warning: true,
    info: true
  })

  const handleChange = event => {
    const update = {
      ...state,
      [event.target.name]: event.target.checked
    }

    setState(update)
    setFiltered(
      notifyList.filter(
        item =>
          (item.variant === variants.success && update.success) ||
          (item.variant === variants.error && update.error) ||
          (item.variant === variants.warning && update.warning) ||
          (item.variant === variants.info && update.info)
      )
    )
  }

  const handleDelete = item => () => {
    dispatch(deleteNotify(item))
    setFiltered(prev => prev.filter(msg => msg.id !== item.id))
  }

  const handleClearAll = () => {
    dispatch(clearAll())
    setFiltered([])
  }
  return (
    <>
      <IconButton
        aria-label="cart"
        onClick={() => {
          setOpen(true)
        }}
      >
        <Badge badgeContent={notifyList.length} color="primary">
          <NotificationsIcon color="secondary" />
        </Badge>
      </IconButton>
      {open && (
        <DialogCustom
          open={open}
          handleClose={() => setOpen(false)}
          title="Notificaciones"
          maxWidth="md"
        >
          <List
            sx={{ width: '100%', height: '80vh' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <Stack direction="row" justifyContent="space-between">
                  <FormGroup
                    sx={{
                      color: 'text.primary',
                      display: { xs: 'flex', md: 'block' }
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={state.success}
                          onChange={handleChange}
                          name={variants.success}
                        />
                      }
                      label={variantName[variants.success]}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={state.error}
                          onChange={handleChange}
                          name={variants.error}
                        />
                      }
                      label={variantName[variants.error]}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={state.warning}
                          onChange={handleChange}
                          name={variants.warning}
                        />
                      }
                      label={variantName[variants.warning]}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={state.info}
                          onChange={handleChange}
                          name={variants.info}
                        />
                      }
                      label={variantName[variants.info]}
                    />
                  </FormGroup>
                  <Button
                    fullWidth={false}
                    variant="text"
                    startIcon={<DeleteIcon />}
                    onClick={handleClearAll}
                  >
                    Vaciar
                  </Button>
                </Stack>
              </ListSubheader>
            }
          >
            {filtered.length === 0 && (
              <Typography color="text.secondary" textAlign="center">
                Sin notificaciones
              </Typography>
            )}
            {filtered.map(item => (
              <Notify
                key={item.id}
                item={item}
                handleDelete={handleDelete(item)}
              />
            ))}
          </List>
        </DialogCustom>
      )}
    </>
  )
}

export default NotificationButton
