import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useQuery } from '@tanstack/react-query'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid2'
import Button from '@mui/material/Button'

import { bedSchema, INITIAL_BED } from '../schemas'
import { internInitalService } from '@/services'
import { showNotify } from '@/providers/Notify'
import { AutocompleteForm, DialogCustom } from '@/components'
import { formatBed } from '@/helpers'

export const CardBedAssigneds = ({ open, setOpen, handleAssignedBed }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ resolver: yupResolver(bedSchema), defaultValues: INITIAL_BED })
  const [beds, setBeds] = useState([])

  const queryArea = useQuery({
    queryKey: ['get-areas'],
    queryFn: () => internInitalService.getAreas(),
    enabled: !!open,
    placeholderData: previousData => previousData || []
  })

  const handleGetBeds = area => {
    internInitalService
      .getBedsAvailable(area.id)
      .then(setBeds)
      .catch(e => showNotify.error(e))
  }

  const onSubmit = data => {
    handleAssignedBed(data)
    clear()
  }

  const clear = () => {
    reset()
    setBeds([])
    setOpen(false)
  }

  return (
    <Stack spacing={2}>
      {open && (
        <DialogCustom title="Ubicación" open={open} handleClose={clear} maxWidth="xs">
          <Grid container component="form" onSubmit={handleSubmit(onSubmit)} onReset={clear} spacing={2} sx={{ p: 2 }}>
            <Grid size={{ xs: 12 }}>
              <AutocompleteForm
                control={control}
                name="area"
                label="Area"
                defaultValue={null}
                options={queryArea.data}
                error={!!errors?.area}
                helperText={errors?.area?.message}
                onChange={area => {
                  if (area) {
                    handleGetBeds(area)
                  } else {
                    clear()
                  }
                }}
                renderLabel={option => option.name}
                renderOption={option => option.name}
                isOptionEqualToValue={(opt, val) => opt.id === val.id}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <AutocompleteForm
                control={control}
                name="bed"
                label="Camas"
                defaultValue={null}
                options={beds}
                error={!!errors?.bed}
                helperText={errors?.bed?.message}
                renderLabel={option => formatBed(option)}
                renderOption={option => <Typography variant="caption">{formatBed(option)}</Typography>}
                isOptionEqualToValue={(opt, val) => opt.id === val.id}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Button type="submit">Guardar</Button>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Button type="reset" variant="outlined">
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </DialogCustom>
      )}
    </Stack>
  )
}
