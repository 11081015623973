import Axios from 'axios'

export const axiosWhatsApp = Axios.create({
  baseURL: import.meta.env.VITE_API_WHATSAPP,
  withCredentials: false,
  credentials: true
})

axiosWhatsApp.interceptors.request.use(
  config => config,
  error => Promise.reject(error)
)

axiosWhatsApp.interceptors.response.use(
  config => config,
  error => Promise.reject(error)
)

export default axiosWhatsApp
