import Axios from 'axios'

export const axiosICD = Axios.create({
  baseURL: import.meta.env.VITE_API_ICD,
  withCredentials: false,
  credentials: true,
  headers: {
    accept: 'application/json',
    'API-Version': 'v2',
    'Accept-Language': 'es'
  }
})

axiosICD.interceptors.request.use(
  config => config,
  error => Promise.reject(error)
)

axiosICD.interceptors.response.use(
  config => config,
  error => Promise.reject(error)
)

export default axiosICD
