import { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid2'
import TextField from '@mui/material/TextField'

function RightMenu({ setDiagnostic, diagnostic = '' }) {
  const [diag, setDiag] = useState('')
  useEffect(() => {
    setDiag(diagnostic)
  }, [diagnostic])

  return (
    <Grid>
      <TextField
        multiline
        minRows={3}
        label="Diagnostico para impresión"
        value={diag}
        onChange={e => setDiag(e.target.value)}
        onBlur={() => setDiagnostic(diag)}
      />
    </Grid>
  )
}

export default RightMenu
