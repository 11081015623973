import { axios } from '@/boot'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = []

export const actionGetProducts = createAsyncThunk(
  'products/getProducts',
  async () => {
    const resp = await axios.get('v1/products')
    return resp.data
  }
)

export const productSlice = createSlice({
  name: 'products',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(actionGetProducts.fulfilled, (state, action) => {
      return action.payload
    })
  }
})

// Action creators are generated for each case reducer function
// export const {} = productSlice.actions

export default productSlice.reducer
