import { Suspense, lazy } from 'react'
import { Route } from 'react-router-dom'

import MedicalHistoryLayout from './index'
import LoadingPage from '../Loading'
import { PrivateRouteList } from '@/routers/routerList'

const TabPanelAll = lazy(() => import('@/pages/medical_history/TabPanelAll'))
const TabPanelMedicalHistory = lazy(() =>
  import('@/pages/medical_history/TabPanelMedicalHistory')
)
const TabPanelLaboratory = lazy(() =>
  import('@/pages/medical_history/TabPanelLaboratory')
)
const TabPanelImaging = lazy(() =>
  import('@/pages/medical_history/TabPanelImaging')
)
const TabPanelComparative = lazy(() =>
  import('@/pages/medical_history/TabPanelComparative')
)
const TabPanelMedicalCertificate = lazy(() =>
  import('@/pages/medical_history/TabPanelMedicalCertificate')
)

export const routerMedicalHistory = (
  <Route
    path={PrivateRouteList.MEDICAL_HISTORY.ROOT}
    element={<MedicalHistoryLayout />}
  >
    <Route
      index
      element={
        <Suspense fallback={<LoadingPage />}>
          <TabPanelAll />
        </Suspense>
      }
    />
    <Route
      path={PrivateRouteList.MEDICAL_HISTORY.HISTORY}
      element={
        <Suspense fallback={<LoadingPage />}>
          <TabPanelMedicalHistory />
        </Suspense>
      }
    />
    <Route
      path={PrivateRouteList.MEDICAL_HISTORY.LABORATORY}
      element={
        <Suspense fallback={<LoadingPage />}>
          <TabPanelLaboratory />
        </Suspense>
      }
    />
    <Route
      path={PrivateRouteList.MEDICAL_HISTORY.IMAGENOLOGY}
      element={
        <Suspense fallback={<LoadingPage />}>
          <TabPanelImaging />
        </Suspense>
      }
    />
    <Route
      path={PrivateRouteList.MEDICAL_HISTORY.COMPARATIVE_LABORATORIES}
      element={
        <Suspense fallback={<LoadingPage />}>
          <TabPanelComparative />
        </Suspense>
      }
    />
    <Route
      path={PrivateRouteList.MEDICAL_HISTORY.CERTIFICATE}
      element={
        <Suspense fallback={<LoadingPage />}>
          <TabPanelMedicalCertificate />
        </Suspense>
      }
    />
  </Route>
)
