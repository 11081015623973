import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer
} from '@react-pdf/renderer'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { format_name } from '@/helpers/format_name'

const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    flexDirection: 'column',
    padding: 35
  },
  content: {
    padding: 10
  },
  date: {
    padding: 0
  },
  patient: {
    paddingVertical: 5
  },
  footer: {
    marginTop: 50,
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  }
})
function ReferPDF({ prescription, diagnostic, patient }) {
  const { user } = useSelector(state => state.auth)
  return (
    <PDFViewer width="100%" style={{ height: 'calc(100vh - 180px)' }}>
      <Document>
        <Page size="A5" orientation="portrait" style={styles.page}>
          <View style={styles.patient}>
            <Text>Dr: {format_name(user)}</Text>
          </View>
          <View style={styles.patient}>
            <Text>Paciente: {format_name(patient)}</Text>
          </View>
          <View style={styles.content}>
            <Text> Historial: </Text>
            <Text>{prescription}</Text>
          </View>
          <View style={styles.content}>
            <Text> Dx: </Text>
            <Text>{diagnostic || ''}</Text>
          </View>
          <View style={styles.date}>
            <Text>Fecha: {moment().format('L')}</Text>
          </View>
          <View style={styles.footer}>
            <Text>...........</Text>
            <Text>Firma</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default ReferPDF
