import axios from '@/boot/axios'

const path = 'v1/products'

class ProductService {
  async checkStock(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/stock/${id}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  async simecSynchronization() {
    return new Promise((resolve, reject) => {
      axios
        .put(`${path}/sync-product-simec`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  async getProductsPharmacy(search) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/pharmacy`, { params: { text: search } })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  async getProductsPharmacyStockStore(product_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/get-stock-store/${product_id}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  async getProductsBranchOffice(product_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/get-product-branch-office/${product_id}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  async getProductsMostSellingImaging() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/most-selling-imaging`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export const productService = new ProductService()
