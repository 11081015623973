import { useState } from 'react'

import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import AsignedDialog from './asigned/AsignedDialog'

import { assignedDoctorService } from '@/services'
import { useGlobalContext } from '@/contexts'
import { format_name } from '@/helpers'
import { showNotify } from '@/providers/Notify'

function ListInCharge({ patient, asignedDoctor, setAsignedDoctor }) {
  const [dialog, setDialog] = useState(false)
  const { setLoading, showConfirmation } = useGlobalContext()

  const handleDelete = id => {
    showConfirmation('Asignar doctor', '¿Quitar doctor asignado?', () => {
      setLoading(true)
      assignedDoctorService
        .deleteAssignedDoctor(id)
        .then(data => {
          setAsignedDoctor(prev => prev.filter(doctor => doctor.id !== data.id))
          showNotify.success('Eliminado doctor asignado')
        })
        .finally(() => setLoading(false))
    })
  }

  return (
    <>
      <Card>
        <CardContent>
          <List dense>
            <ListItem
              divider
              disablePadding
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="comments"
                  onClick={() => {
                    if (patient !== null) setDialog(true)
                  }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              }
            >
              <Tooltip title="Asignará a otro doctor para que edite la información del paciente">
                <ListItemText primary="Doctores asignados" />
              </Tooltip>
            </ListItem>
            {asignedDoctor.map(item => (
              <ListItem
                key={item.id}
                disablePadding
                secondaryAction={
                  <IconButton edge="end" onClick={() => handleDelete(item.id)}>
                    <DeleteIcon color="error" />
                  </IconButton>
                }
              >
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary={format_name(item.doctor)} />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
      {dialog && (
        <AsignedDialog
          open={dialog}
          handleClose={() => setDialog(false)}
          patient={patient}
          asignedDoctor={asignedDoctor}
          setAsignedDoctor={setAsignedDoctor}
        />
      )}
    </>
  )
}

export default ListInCharge
