import { axios } from '@/boot'

const path = 'v1/branch-office'

class Branch {
  getBranchOffice() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/services`)
        .then(res => resolve(res.data))
        .catch(e => reject(e))
    })
  }
}

export const branchOfficeService = new Branch()
