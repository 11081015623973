import { useEffect, useState } from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'

import { icdService } from '@/services'

export const AutocompleteICD = ({ onChange, data = [], label, color, helperText, ...others }) => {
  const [icd10, setIcd10] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [value, setValue] = useState([])

  useEffect(() => {
    if (data) {
      setValue(data)
    }
  }, [data])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== '' && search && search.length > 2) {
        setLoading(true)
        icdService
          .getSearchICD11(search)
          .then(setIcd10)
          .finally(() => setLoading(false))
      } else clearTimeout(delayDebounceFn)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  return (
    <Autocomplete
      {...others}
      multiple
      limitTags={5}
      disableClearable
      loading={loading}
      filterSelectedOptions
      loadingText="Cargando..."
      noOptionsText="Sin resultados"
      value={value}
      options={icd10}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index })
          return (
            <Tooltip key={key} title={option}>
              <Chip label={option} key={key} {...tagProps} sx={{ textOverflow: 'ellipsis', maxWidth: '200px' }} />
            </Tooltip>
          )
        })
      }
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          onChange={e => {
            if (e.target.value !== '') setSearch(e.target.value)
            else setIcd10([])
          }}
          onKeyDown={event => {
            if (event.key === 'Backspace') {
              event.stopPropagation()
            }
          }}
          helperText={helperText}
        />
      )}
      sx={{ bgcolor: color }}
      onChange={(_, value) => {
        setValue(value)
        onChange(value)
      }}
    />
  )
}
