import { WITH_QR } from '@/constants'

export const format_name_single = user => {
  if (!user) return ''

  return `${user?.name || ''}`.trim()
}

export const format_name = user => {
  if (user) {
    return `${user?.name || ''} ${user?.lastname || ''} ${
      user?.mother_lastname || ''
    }`.trim()
  }
  return ''
}

export const format_name_ci = user => {
  if (user) {
    return `${user?.name || ''} ${user?.lastname || ''} ${
      user?.mother_lastname || ''
    } ${user?.ci || ''}  ${user?.extension || ''} ${
      user?.expedition || ''
    } `.trim()
  }
  return ''
}
export const format_name_ci_ext = user => {
  if (user) {
    return `${user?.name || ''} ${user?.lastname || ''} ${
      user?.mother_lastname || ''
    } ${user?.ci || ''}  ${user?.extension || ''} ${
      user?.expedition || ''
    }`.trim()
  }
  return ''
}

export const format_ci_ext = user => {
  if (user) {
    return `${user?.ci || ''} ${user?.extension || ''} ${
      user?.expedition || ''
    }`.trim()
  }
  return ''
}

export const format_ci_qr = user => {
  if (user) {
    return `${user?.ci || ''} ${user?.extension || ''} ${
      user?.ci_qr ? WITH_QR : user?.expedition || ''
    }`.trim()
  }
  return ''
}

export const format_name_age = user => {
  if (user) {
    return `${user?.name || ''} ${user?.lastname || ''} ${
      user?.mother_lastname || ''
    } ${user.age ? user.age : 'Edad No registrada'}`.trim()
  }
  return ''
}

export const format_lastname = user => {
  if (user) {
    return `${user?.lastname || ''} ${user?.mother_lastname || ''}`.trim()
  }
  return ''
}
