import Toolbar from '@mui/material/Toolbar'
import Stack from '@mui/material/Stack'

export const CustomToolbar = ({ children }) => {
  return (
    <Toolbar variant="dense">
      <Stack
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        {children}
      </Stack>
    </Toolbar>
  )
}
