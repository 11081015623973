import { Suspense, lazy } from 'react'

import { Route } from 'react-router-dom'
import { PrivateRouteList } from '@/routers/routerList'
import LoadingPage from '../Loading'

const SaleDetailPrint = lazy(() => import('@/pages/cashier/SaleDetailPrint'))
const Pharmacy = lazy(() => import('@/pages/pharmacy/Pharmacy'))

export const routerPharmacy = (
  <Route path={PrivateRouteList.PHARMACY}>
    <Route
      index
      element={
        <Suspense fallback={<LoadingPage />}>
          <Pharmacy />
        </Suspense>
      }
    />
    <Route
      path={PrivateRouteList.CASHIER.SALE_PRINT}
      element={
        <Suspense fallback={<LoadingPage />}>
          <SaleDetailPrint />
        </Suspense>
      }
    />
  </Route>
)
