import { useState, useEffect } from 'react'

import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import Collapse from '@mui/material/Collapse'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

export const IsSmoke = ({ onChange, smokes, is_smoke, color, timeDelay = 500 }) => {
  const [smoke, setSmoke] = useState('0')
  const [text, setText] = useState('')

  useEffect(() => {
    setSmoke(is_smoke ? '1' : '0')
    if (smokes !== '') setText(smokes)
  }, [is_smoke])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (text !== smokes) onChange(text, smoke === '1')
    }, timeDelay)
    return () => clearTimeout(delayDebounceFn)
  }, [text])

  const handleChange = e => {
    setSmoke(e.target.value)
    if (e.target.value === '0') {
      setText('')
      onChange(null, e.target.value === '1')
    } else {
      onChange(text, e.target.value === '1')
    }
  }
  return (
    <FormControl sx={{ bgcolor: color }}>
      <FormLabel id="medical-history-radio-buttons-group">¿El paciente fuma?</FormLabel>
      <RadioGroup
        aria-labelledby="medical-history-radio-buttons-group"
        name="medical-history-radio-buttons-group"
        value={smoke}
        onChange={handleChange}
      >
        <FormControlLabel value="0" control={<Radio />} label="No, fuma" labelPlacement="end" />
        <FormControlLabel value="1" control={<Radio />} label="Si, fuma" labelPlacement="end" />
      </RadioGroup>
      <Collapse in={smoke === '1'} timeout="auto" unmountOnExit>
        <OutlinedInput
          size="small"
          fullWidth
          placeholder="Descripción"
          multiline
          value={text}
          onChange={e => setText(e.target.value)}
        />
      </Collapse>
    </FormControl>
  )
}
