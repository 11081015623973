import { axios } from '@/boot'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const INITIAL_STATE = []

export const actionGetTemplate = createAsyncThunk(
  'template/getTemplates',
  async () => {
    const resp = await axios.get('v1/laboratory-template')
    return resp.data
  }
)

export const templateSlice = createSlice({
  name: 'template',
  initialState: INITIAL_STATE,
  reducers: {
    actionPushTemplate: (state, action) => {
      let exist = false
      const template = state.map(template => {
        if (template.id === action.payload.id) {
          exist = true
          return action.payload
        }
        return template
      })
      if (exist) return template
      return [...state, action.payload]
    },
    actionDeleteTemplate: (state, action) => {
      const newState = state.filter(
        template => template.id !== action.payload.id
      )
      return newState
    }
  },
  extraReducers: builder => {
    builder.addCase(actionGetTemplate.fulfilled, (state, action) => {
      return action.payload
    })
  }
})

// Action creators are generated for each case reducer function
export const { actionDeleteTemplate, actionPushTemplate } =
  templateSlice.actions

export default templateSlice.reducer
