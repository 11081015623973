import { useState, useEffect } from 'react'

export const useColors = () => {
  const [color, setColor] = useState(undefined)
  const [action, setAction] = useState('')

  useEffect(() => {
    if (action === 'success') {
      setColor('rgba(0,255,0,.2)')
      setTimeout(() => {
        setColor(undefined)
        setAction('')
      }, 1000)
    }
    if (action === 'error') {
      setColor('rgba(255,0,0,.2)')
      setTimeout(() => {
        setColor(undefined)
        setAction('')
      }, 1000)
    }
  }, [action])

  return { setAction, color }
}
