import CryptoJS from 'crypto-js'

class CryptoStorage {
  getItemStorage(key, encript = false) {
    try {
      const cipherText = localStorage.getItem(key) || ''
      if (encript && cipherText) {
        const bytes = CryptoJS.AES.decrypt(
          cipherText,
          import.meta.env.VITE_CRYPTO_KEY ?? ''
        )
        const originalText = bytes.toString(CryptoJS.enc.Utf8)
        return originalText
      } else return cipherText
    } catch (e) {
      return ''
    }
  }

  setItemStorage(key, payload, encript = false) {
    try {
      if (encript) {
        const cipherText = CryptoJS.AES.encrypt(
          JSON.stringify(payload),
          import.meta.env.VITE_CRYPTO_KEY ?? ''
        ).toString()
        localStorage.setItem(key, cipherText)
      } else localStorage.setItem(key, JSON.stringify(payload))
    } catch (error) {
      console.error(error)
    }
  }

  removeItemStorage(key) {
    try {
      localStorage.removeItem(key)
    } catch (error) {
      console.error(error)
    }
  }

  clearLocalStorage() {
    try {
      localStorage.clear()
    } catch (error) {
      console.error(error)
    }
  }

  encryptForURL(payload) {
    try {
      const encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(payload),
        import.meta.env.VITE_CRYPTO_KEY ?? ''
      ).toString()
      const encoded = CryptoJS.enc.Base64.parse(encrypted).toString(
        CryptoJS.enc.Hex
      )
      return encoded
    } catch (error) {
      return ''
    }
  }

  decryptForURL(encoded) {
    try {
      const decoded = CryptoJS.enc.Hex.parse(encoded).toString(
        CryptoJS.enc.Base64
      )
      const decrypted = CryptoJS.AES.decrypt(
        decoded,
        import.meta.env.VITE_CRYPTO_KEY ?? ''
      ).toString(CryptoJS.enc.Utf8)
      return decrypted
    } catch (e) {
      return ''
    }
  }
}
export const encryptStorage = new CryptoStorage()
