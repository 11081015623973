import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'

import { processDate } from '@/utils'
import { formatBed } from '@/helpers'

const ListItemCustom = ({ onEdit, primary, secondary, editable }) => {
  return (
    <ListItem
      disableGutters
      disablePadding
      secondaryAction={
        editable && (
          <IconButton edge="end" aria-label="delete" onClick={onEdit}>
            <EditIcon />
          </IconButton>
        )
      }
    >
      <ListItemText primary={primary} secondary={secondary} secondaryTypographyProps={{ variant: 'caption' }} />
    </ListItem>
  )
}
export const CardAddmissionDetail = ({ addmissionDetail, handleEditBed }) => {
  return (
    <Stack>
      <List dense>
        <ListItemCustom editable onEdit={handleEditBed} primary={formatBed(addmissionDetail?.bed)} secondary="Ubicación" />
        <ListItemCustom primary={addmissionDetail?.specialIntruccions} secondary="Instrucciones especiales" />
        <ListItemCustom primary={addmissionDetail?.reasonForAdmission} secondary="Razón de admisión" />
        <ListItemCustom primary={processDate(addmissionDetail?.dateOfEntry).fullDateTime} secondary="Ingreso" />
      </List>
    </Stack>
  )
}
