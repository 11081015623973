import { Children } from 'react'

import Stack from '@mui/material/Stack'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

export const DrawerRightAddmission = ({ children }) => {
  return (
    <Stack spacing={1}>
      {Children.map(children, child => (
        <Card>
          <CardContent>{child}</CardContent>
        </Card>
      ))}
    </Stack>
  )
}
