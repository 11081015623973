import { PrivateRouteList } from '@/routers/routerList'
import { Suspense, lazy } from 'react'
import { Route } from 'react-router-dom'
import LoadingPage from '../Loading'

const Imagenology = lazy(() => import('@/pages/imagen'))
const ImagenologyDetail = lazy(() => import('@/pages/imagen/ImagenologyDetail'))

export const routerImagenology = (
  <Route path={PrivateRouteList.IMAGEN.ROOT}>
    <Route
      index
      element={
        <Suspense fallback={<LoadingPage />}>
          <Imagenology />
        </Suspense>
      }
    />

    <Route
      path={PrivateRouteList.IMAGEN.IMAGEN_DETAIL}
      element={
        <Suspense fallback={<LoadingPage />}>
          <ImagenologyDetail />
        </Suspense>
      }
    />
  </Route>
)
