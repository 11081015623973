import { useState } from 'react'

import ConfirmationDialog from '@/components/ConfirmationDialog'
import Loading from '@/components/Loading'
import { MainContext } from '@/contexts'

function WraperUtilities({ children }) {
  const [loading, setLoading] = useState(false)
  const [dialog, setDialog] = useState({
    show: false,
    title: '',
    message: '',
    onConfirm: () => {},
    onClose: () => {}
  })

  function showConfirmation(
    title = '',
    message = '',
    handleConfirm = null,
    handleClose = null
  ) {
    setDialog({
      show: true,
      title,
      message,
      onConfirm() {
        if (handleConfirm) {
          handleConfirm()
        }
        setDialog(prev => ({ ...prev, show: false }))
      },
      onClose() {
        if (handleClose) {
          handleClose()
        }
        setDialog(prev => ({ ...prev, show: false }))
      }
    })
  }

  return (
    <MainContext.Provider value={{ setLoading, showConfirmation }}>
      {dialog.show && (
        <ConfirmationDialog
          open={dialog.show}
          title={dialog.title}
          message={dialog.message}
          handleOk={dialog.onConfirm}
          handleClose={dialog.onClose}
        />
      )}
      {children}
      {loading && <Loading open={loading} />}
    </MainContext.Provider>
  )
}

export default WraperUtilities
