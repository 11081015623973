import { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'

import LoadingPage from '../Loading'
import { PrivateRouteList } from '@/routers'
import { LayoutFormDetail } from './layout'

const Emergency = lazy(() => import('./views').then(module => ({ default: module.Emergency })))
const AdmissionFormDetail = lazy(() => import('./views').then(module => ({ default: module.AdmissionFormDetail })))
const Epicrisis = lazy(() => import('./views').then(module => ({ default: module.Epicrisis })))
const DocumentsFollow = lazy(() => import('./views').then(module => ({ default: module.DocumentsFollow })))
const DocumentFollowList = lazy(() => import('./views').then(module => ({ default: module.DocumentFollowList })))

export const routerEmergency = (
  <Route path={PrivateRouteList.EMERGENCY.ROOT}>
    <Route
      index
      element={
        <Suspense fallback={<LoadingPage />}>
          <Emergency />
        </Suspense>
      }
    />

    <Route path={PrivateRouteList.EMERGENCY.ADMISSION}>
      <Route path={PrivateRouteList.EMERGENCY.ADMISSION_ID} element={<LayoutFormDetail />}>
        <Route
          index
          element={
            <Suspense fallback={<LoadingPage />}>
              <AdmissionFormDetail />
            </Suspense>
          }
        />

        <Route path={PrivateRouteList.EMERGENCY.DOCUMENT_LIST}>
          <Route
            index
            element={
              <Suspense fallback={<LoadingPage />}>
                <DocumentFollowList />
              </Suspense>
            }
          />
          <Route
            path={PrivateRouteList.EMERGENCY.DOCUMENTS}
            element={
              <Suspense fallback={<LoadingPage />}>
                <DocumentsFollow />
              </Suspense>
            }
          />
        </Route>

        <Route path={PrivateRouteList.EMERGENCY.EPICRISIS}>
          <Route path={PrivateRouteList.EMERGENCY.EPICRISIS_ID}>
            <Route
              index
              element={
                <Suspense fallback={<LoadingPage />}>
                  <Epicrisis />
                </Suspense>
              }
            />
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>
)
