import { axios } from '@/boot'

const path = 'v1/imaging'
const pathMinio = 'v1/file-minio'
const imagingDetailPath = 'v1/imaging-detail'

class Imagenology {
  getImagingMedicalDetailById(medical_detail_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/medical-detail/${medical_detail_id}`)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(e => reject(e))
    })
  }

  getImagingDetailById(imagingDetailId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/${imagingDetailId}`)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(e => reject(e))
    })
  }

  getImagingPatientById(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/patient/${id}`, { params: { patient_id: id } })
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  }

  putImagingId(imagingId, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${path}/${imagingId}`, data)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(e => reject(e))
    })
  }

  getImagingToDay({ from, to }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/today`, { params: { from, to } })
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  }

  putImagingDetail(imagingDetailId, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${imagingDetailPath}/${imagingDetailId}`, data)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(e => reject(e))
    })
  }

  postFileMinio({ name, url, etag, key, versionId, state, imaging_detail_id }) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathMinio, {
          name,
          url,
          etag,
          key,
          versionId,
          state,
          imaging_detail_id
        })
        .then(res => resolve(res.data))
        .catch(e => reject(e))
    })
  }

  deleteFileMinio(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${pathMinio}/${id}`)
        .then(res => resolve(res.data))
        .catch(e => reject(e))
    })
  }

  deleteAnnotationMinio(annotationId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${pathMinio}/annotation/${annotationId}`)
        .then(res => resolve(res.data))
        .catch(e => reject(e))
    })
  }
}

export const imagenologyService = new Imagenology()
