import { useCallback, useEffect, useState } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid2'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useGlobalContext } from '@/contexts/MainContext'
import AutocompleteTemplate from '../../../cashier/laboratory-list/AutocompleteTemplate'
import { laboratoryTemplateService } from '@/services/laboratory_template_service'
import axios from '@/boot/axios'
import TemplatesDialog from '../../../cashier/laboratory-list/TemplatesDialog'
import RightMenu from './RightMenu'
import { searchParamNames, stateSalesType } from '@/constants'
import LaboratoryList from '@/components/LaboratoryList'
import { PrivateRouteList } from '@/routers/routerList'
import { showNotify } from '@/providers/Notify'

function LaboratoryDialog({ open, handleClose, patient, medical_history_detail_id, diagn }) {
  const navigate = useNavigate()
  const { setLoading } = useGlobalContext()
  const labs = useSelector(state => state.reducerLaboratories)
  const { user } = useSelector(state => state.auth)
  const [laboratories, setLaboratories] = useState(Object.keys(labs).length > 0 ? JSON.parse(JSON.stringify(labs)) : {})
  const [template, setTemplate] = useState(null)
  const [diagnostic, setDiagnostic] = useState('')
  const [total, setTotal] = useState(0)
  const [templateDialog, setTemplateDialog] = useState(false)
  const { showConfirmation } = useGlobalContext()

  useEffect(() => {
    if (diagn) {
      setDiagnostic(diagn)
    }
  }, [diagn])

  const handleGetTemplate = useCallback(template => {
    if (template !== null) {
      setLoading(true)
      clearForm() &&
        laboratoryTemplateService
          .getLaboratoryTemplate({ id: template.id })
          .then(data => {
            let newLabs = {}
            let newTotal = 0
            data.template_product.forEach(template => {
              const el = template.product
              const obj = laboratories
              newLabs = {
                ...obj,
                [el.sub_category]: obj[el.sub_category].map(item => {
                  if (item.id === el.id) {
                    newTotal += Number(item.price)
                    item.purchase = true
                    return item
                  }
                  return item
                })
              }
            })
            if (Object.keys(newLabs).length > 0) {
              setLaboratories(newLabs)
              setTotal(newTotal)
            }
          })
          .finally(() => setLoading(false))
    } else clearForm()
  }, [])

  const clearForm = () => {
    for (const group in laboratories) {
      setLaboratories({
        ...laboratories,
        [group]: laboratories[group].map(item => {
          if (item.purchase) {
            item.purchase = false
            return item
          }
          return item
        })
      })
    }
    setTotal(0)
    setTemplate(null)
    return true
  }

  const handleAcept = () => {
    const detailSale = []
    for (const group in laboratories) {
      laboratories[group].forEach(laboratory => {
        if (laboratory.purchase) {
          detailSale.push({
            id: '',
            sale_price: Number(laboratory.price),
            discount: 0,
            invoiced: false,
            product_id: laboratory.id,
            quantity: 1,
            name: laboratory.name,
            meet_id: '',
            meet: null
          })
        }
      })
    }

    if (!(detailSale.length > 0)) {
      showNotify.warning('Seleccione un laboratorio')
      return
    }

    showConfirmation('Confirmar laboratorio', 'Al aceptar se asignaran laboratorios al paciente', () => {
      setLoading(true)
      const model = {
        total_price: total,
        state: stateSalesType.NO_PAGADO,
        description: diagnostic,
        patient_id: patient.id,
        cashier_id: user.id,
        transfer: 0,
        sale_detail: detailSale,
        id: '',
        medical_history_detail_id
      }

      axios
        .post('v1/sales', model)
        .then(({ data }) => {
          navigate({
            pathname: PrivateRouteList.SALE_LABORATORY,
            search: new URLSearchParams({
              [searchParamNames.saleId]: data.id,
              [searchParamNames.diagnostic]: diagnostic
            }).toString()
          })
        })
        .finally(() => setLoading(false))
    })
  }

  return (
    <>
      <Dialog fullScreen open={open} scroll="paper">
        <DialogContent dividers>
          <Grid container justifyContent="center" spacing={2}>
            <Grid
              size={{ xs: 12, sm: 10 }}
              sx={{
                overflow: 'auto',
                maxHeight: 'calc(100vh - 20px)'
              }}
            >
              <LaboratoryList
                laboratories={laboratories}
                setLaboratories={setLaboratories}
                setTotal={setTotal}
                total={total}
                disablePrice
              />
            </Grid>

            <Grid size={{ xs: 12, sm: 2 }}>
              <Grid container direction="column" spacing={3}>
                <Grid>
                  <AutocompleteTemplate
                    value={template}
                    onChangeCallBack={value => {
                      handleGetTemplate(value)
                      setTemplate(value)
                    }}
                  />
                </Grid>
                <Grid>
                  <Button variant="outlined" onClick={() => setTemplateDialog(true)}>
                    Plantillas
                  </Button>
                </Grid>
                <Grid>
                  <Button
                    variant="outlined"
                    color="warning"
                    onClick={() => {
                      clearForm()
                    }}
                  >
                    Deseleccionar
                  </Button>
                </Grid>
                <Grid>
                  <Button color="error" onClick={handleClose}>
                    Cerrar Ventana
                  </Button>
                </Grid>
                <Grid>
                  <Button onClick={handleAcept}>emitir laboratorio</Button>
                </Grid>
                <RightMenu setDiagnostic={setDiagnostic} diagnostic={diagnostic} />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {templateDialog && <TemplatesDialog open={templateDialog} handleClose={() => setTemplateDialog(false)} />}
    </>
  )
}

export default LaboratoryDialog
