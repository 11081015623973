import axiosICD from '../boot/axiosICD'

const url = 'icd/entity/search'
const params = {
  chapterFilter:
    '10;11;12;13;14;15;16;17;18;19;20;21;22;23;24;25;01;02;03;04;05;06;07;08;09;',
  useFlexisearch: true,
  flatResults: true,
  highlightingEnabled: false
}

class IcdService {
  getSearchUnderlying(search, medical_history_id) {
    return new Promise((resolve, reject) => {
      axiosICD
        .get(url, { params: { q: `${search}%`, ...params } })
        .then(({ data }) => {
          const filter = []
          data?.destinationEntities.forEach(item => {
            filter.push({
              id: '',
              disease: item.title,
              medical_history_id,
              diagnostic_id: '',
              medical_history_detail_id: ''
            })

            if (item?.matchingPVs) {
              item.matchingPVs.forEach(elem =>
                filter.push({
                  id: '',
                  disease: elem.label,
                  medical_history_id,
                  diagnostic_id: '',
                  medical_history_detail_id: ''
                })
              )
            }
          })
          resolve(filter)
        })
        .catch(e => reject(e))
    })
  }

  getSearchICD11(search) {
    return new Promise((resolve, reject) => {
      axiosICD
        .get(url, { params: { q: `${search}%`, ...params } })
        .then(({ data }) => {
          const filter = []
          data?.destinationEntities.forEach(item => {
            filter.push(item.title)

            if (item?.matchingPVs) {
              item.matchingPVs.forEach(elem => filter.push(elem.label))
            }
          })
          resolve(filter)
        })
        .catch(e => reject(e))
    })
  }
}
export const icdService = new IcdService()
