import { Fragment } from 'react'

import Typography from '@mui/material/Typography'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import FolderIcon from '@mui/icons-material/Folder'
import { amber } from '@mui/material/colors'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import WarningIcon from '@mui/icons-material/Warning'
import Stack from '@mui/material/Stack'
import Card from '@mui/material/Card'

import ButtonShowFile from './ButtonShowFile'
import { TimeAgoEs } from './TimeAgoEs'

const formatReference = reference => {
  if (!reference) return ''
  return `${reference.value_reference} ${reference.unit_measurement}`
}

const FormatRows = ({ item }) => {
  if (
    (!item.result && item.result === '' && (item.within_range !== '' || item.within_range)) ||
    item.out_of_range !== '' ||
    item.out_of_range
  ) {
    return (
      <>
        <TableCell
          align="center"
          sx={{
            color: 'green',
            borderLeft: 1,
            borderRight: 1,
            borderColor: 'divider'
          }}
        >
          {item?.within_range || ''}
        </TableCell>
        <TableCell align="center" sx={{ color: 'red' }}>
          {item?.out_of_range || ''}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            borderLeft: 1,
            borderRight: 1,
            borderColor: 'divider'
          }}
        >
          {formatReference(item.reference_value)}
        </TableCell>
      </>
    )
  }
  if (item.result !== '' && item.result && !item.within_range && !item.out_of_range && !item.reference_value) {
    return (
      <TableCell
        colSpan={3}
        sx={{
          borderLeft: 1,
          borderRight: 1,
          borderColor: 'divider'
        }}
      >
        {item?.result || ''}
      </TableCell>
    )
  }
  if (item.result !== '' && item.result && !item.within_range && !item.out_of_range && item.reference_value) {
    return (
      <>
        <TableCell
          colSpan={2}
          sx={{
            borderLeft: 1,
            borderColor: 'divider'
          }}
        >
          {item.result}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            borderLeft: 1,
            borderRight: 1,
            borderColor: 'divider'
          }}
        >
          {formatReference(item.reference_value)} C
        </TableCell>
      </>
    )
  }
  return <TableCell colSpan={3} />
}

const TableLaboratory = ({ sample }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell scope="row">Nombre</TableCell>
            <TableCell
              align="center"
              sx={{
                borderLeft: 1,
                borderRight: 1,
                borderColor: 'divider'
              }}
            >
              Dentro de Rango
            </TableCell>
            <TableCell align="center">Fuera de Rango</TableCell>
            <TableCell
              align="center"
              sx={{
                borderLeft: 1,
                borderRight: 1,
                borderColor: 'divider'
              }}
            >
              Referencia
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {sample?.map((item, index) => (
            <Fragment key={index}>
              <TableRow hover selected>
                <TableCell colSpan={5} scope="row">
                  <Typography
                    variant="caption"
                    sx={{
                      mr: 1,
                      fontWeight: 'bold',
                      textTransform: 'uppercase'
                    }}
                  >
                    {item.group}
                  </Typography>
                </TableCell>
              </TableRow>
              {item.sample_result.map(result => (
                <TableRow hover key={result.id}>
                  <TableCell>{result.name}</TableCell>
                  <FormatRows item={result} />
                  <TableCell align="center">
                    {result.danger && (
                      <Tooltip title="Resultado peligroso">
                        <IconButton>
                          <WarningIcon sx={{ color: 'red' }} fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                    {result?.attached && <ButtonShowFile url={result.attached} />}
                  </TableCell>
                </TableRow>
              ))}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const LaboratoryTreeView = ({ laboratory, table }) => {
  if (table) {
    return (
      <Stack spacing={1}>
        <Stack>
          <b>Descripción: </b>
          <Typography variant="body2" margin={1} whiteSpace="pre-line">
            {laboratory.description}
          </Typography>
          <b>Observaciones: </b>
          <Typography variant="body2" margin={1} whiteSpace="pre-line">
            {laboratory.observation}
          </Typography>
        </Stack>
        <Card>
          <TableLaboratory sample={laboratory.samples || []} />
        </Card>
      </Stack>
    )
  }

  return (
    <SimpleTreeView>
      <TreeItem
        itemId={laboratory.id}
        label={
          <Stack direction="row" spacing={1} alignItems="center">
            <FolderIcon sx={{ color: amber[500] }} />
            <Typography variant="subtitle2"># {laboratory.laboratory_number}</Typography>
            <Typography>Laboratorio</Typography>
            <TimeAgoEs createdAt={laboratory.createdAt} date={laboratory.updatedAt} title="Actualizado:" />
          </Stack>
        }
      >
        <Stack spacing={1}>
          <Stack>
            <b>Descripción: </b>
            <Typography variant="body2" margin={1} whiteSpace="pre-line">
              {laboratory.description}
            </Typography>
            <b>Observaciones: </b>
            <Typography variant="body2" margin={1} whiteSpace="pre-line">
              {laboratory.observation}
            </Typography>
          </Stack>
          <Card>
            <TableLaboratory sample={laboratory.samples || []} />
          </Card>
        </Stack>
      </TreeItem>
    </SimpleTreeView>
  )
}
