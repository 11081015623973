import * as yup from 'yup'

export const waterBalanceSchema = yup.object().shape({
  value: yup.string().required('Campo requerido'),
  typeWaterBalance: yup.string().required('Campo requerido'),
  typeLiquidAdministration: yup.string().required('Campo requerido')
})

export const INITIAL_WATER_BALANCE = {
  value: '',
  typeWaterBalance: '',
  typeLiquidAdministration: ''
}
