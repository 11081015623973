import * as yup from 'yup'

export const surgicalProcedureSchema = yup.object().shape({
  medicalOrder: yup.object().nullable(),

  doctor: yup.object().required('Campo requerido'),
  surgicalPackage: yup.object().required('Campo requerido'),
  bed: yup.object().required('Campo requerido'),
  area: yup.object().required('Campo requerido'),
  surgery: yup.object().required('Campo requerido'),
  usefulnessIntervention: yup.string().required('Campo requerido'),
  benefitsIntervention: yup.string().required('Campo requerido'),
  contraindicationsIntervention: yup.string().required('Campo requerido'),
  risksIntervention: yup.string().required('Campo requerido'),
  durationInHours: yup.string().required('Campo requerido'),
  start: yup.date().required('Campo requerido'),
  end: yup.date().required('Campo requerido')
})

export const INITIAL_SURGICAL_PROCEDURE = {
  medicalOrder: null,

  doctor: null,
  surgicalPackage: null,
  bed: null,
  area: null,
  surgery: null,
  usefulnessIntervention: '',
  benefitsIntervention: '',
  contraindicationsIntervention: '',
  risksIntervention: '',
  durationInHours: '',
  start: null,
  end: null
}
