import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { format_name } from '@/helpers'

export const CardTraitingDoctor = ({ treatingDoctor }) => {
  return (
    <Stack spacing={2}>
      <Typography variant="subtitle1">Doctor</Typography>
      <Typography variant="body2">{format_name(treatingDoctor)}</Typography>
    </Stack>
  )
}
