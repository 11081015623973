import React, { useEffect, useState } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Autocomplete, TextField } from '@mui/material'

import { assignedDoctorService, userService } from '@/services'
import { useGlobalContext } from '@/contexts'
import { format_name } from '@/helpers'
import { showNotify } from '@/providers/Notify'

function AsignedDialog({
  open,
  handleClose,
  asignedDoctor,
  setAsignedDoctor,
  patient
}) {
  const { setLoading } = useGlobalContext()
  const [items, setItems] = useState([])
  const [doctor, setDoctor] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    handleGetDoctors()
  }, [])

  const handleGetDoctors = () => {
    setIsLoading(true)
    userService
      .getUsers({ rol: 'doctor' })
      .then(data => setItems(data))
      .finally(() => setIsLoading(false))
  }

  const handleSubmit = () => {
    if (doctor) {
      let exist = false
      asignedDoctor.forEach(item => {
        if (item.doctor_id === doctor.id) exist = true
      })

      if (!exist) {
        setLoading(true)
        const model = {
          doctor_id: doctor?.id || '',
          patient_id: patient?.id || ''
        }
        assignedDoctorService
          .postAssignedDoctor(model)
          .then(data => {
            setAsignedDoctor(prev => [...prev, data])
            showNotify.success('Doctor asignado')
            handleClose()
          })
          .finally(() => setLoading(false))
      } else showNotify.error('El medico ya esta asignado')
    } else {
      showNotify.info('Seleccione un doctor')
    }
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Asignar doctor</DialogTitle>
      <DialogContent sx={{ minHeight: 150 }} dividers>
        <Autocomplete
          size="small"
          value={doctor}
          options={items}
          loading={isLoading}
          loadingText="Cargando..."
          noOptionsText="No hay opciones"
          getOptionLabel={option => format_name(option)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterSelectedOptions
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              autoComplete="new-text"
              label="Seleccione un doctor"
              size="small"
            />
          )}
          onChange={(e, value) => {
            setDoctor(value)
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          Cancelar
        </Button>
        <Button variant="outlined" autoFocus onClick={handleSubmit}>
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AsignedDialog
