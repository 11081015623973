import * as yup from 'yup'

export const epicrisisSchema = yup.object().shape({
  physicalExamination: yup.string().required('Campo requerido'),
  surgicalProcedureReports: yup.string().required('Campo requerido'),
  evolution: yup.string().required('Campo requerido'),
  finalReference: yup.string().required('Campo requerido'),
  observations: yup.string().required('Campo requerido'),
  dateOfDischarge: yup.date().required('Campo requerido')
})

export const INITIAL_EPICRISIS = {
  physicalExamination: '',
  surgicalProcedureReports: '',
  evolution: '',
  finalReference: '',
  observations: '',
  dateOfDischarge: null
}
