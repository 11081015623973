import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'

const disabledProd = '#ffe6e6'
function LaboratoryCheck({ name, state, setState, setTotal, disablePrice }) {
  return (
    <>
      <Typography
        sx={{
          textAlign: 'center',
          bgcolor: '#333',
          color: '#fff',
          fontSize: 11,
          padding: 0.3
        }}
      >
        {name}
      </Typography>
      {Object.keys(state)?.length > 0 &&
      (!state[name] || state[name]?.length === 0) ? (
        <Typography variant="caption" fontSize={11} width={35}>
          Sin laboratorios
        </Typography>
      ) : (
        state[name]?.map(
          lab =>
            lab.state && (
              <div
                key={lab.id}
                style={{
                  fontSize: 12,
                  backgroundColor: !lab.state ? disabledProd : '',
                  marginTop: 0.4,
                  marginBottom: 0.4,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Checkbox
                  disabled={!lab.state}
                  size="small"
                  color="secondary"
                  sx={{ width: 25, height: 25 }}
                  checked={lab.purchase}
                  onChange={e => {
                    setState({
                      ...state,
                      [name]: state[name].map(item => {
                        if (item.id === lab.id) {
                          e.target.checked
                            ? setTotal(total => total + Number(item.price))
                            : setTotal(total => total - Number(item.price))
                          item.purchase = e.target.checked
                          return item
                        }
                        return item
                      })
                    })
                  }}
                />
                <Typography variant="caption" fontSize={11} width={35}>
                  {!disablePrice && Number(lab.price).toFixed(1)}
                </Typography>
                {lab.name}
              </div>
            )
        )
      )}
    </>
  )
}

export default LaboratoryCheck
