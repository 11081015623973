import * as AWS from 'aws-sdk'

export const s3AWS = new AWS.S3({
  endpoint: import.meta.env.VITE_S3_ENDPOINT,
  region: 'phoenix',
  useAccelerateEndpoint: true,
  accessKeyId: import.meta.env.VITE_S3_KEY,
  secretAccessKey: import.meta.env.VITE_S3_SECRET_KEY,
  sslEnabled: !!import.meta.env.VITE_S3_SSL,
  s3ForcePathStyle: true,
  signatureVersion: 'v4',
  httpOptions: { timeout: 0 }
})
