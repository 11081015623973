import * as yup from 'yup'

export const bedSchema = yup.object().shape({
  bed: yup.object().nullable().required('Este campo es requerido'),
  area: yup.object().nullable().required('Este campo es requerido')
})

export const INITIAL_BED = {
  bed: null,
  area: null
}
