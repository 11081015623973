import { Suspense, lazy } from 'react'
import { Route } from 'react-router-dom'

import { PrivateRouteList } from '@/routers/routerList'
import LoadingPage from '../Loading'

const Cashier = lazy(() => import('@/pages/cashier/Cashier'))
const Laboratory = lazy(() => import('@/pages/cashier/Laboratory'))
const SaleDetailPrint = lazy(() => import('@/pages/cashier/SaleDetailPrint'))

export const routerCashier = (
  <Route path={PrivateRouteList.CASHIER.ROOT}>
    <Route
      index
      element={
        <Suspense fallback={<LoadingPage />}>
          <Cashier />
        </Suspense>
      }
    />
    <Route
      path={PrivateRouteList.CASHIER.SALE_PRINT}
      element={
        <Suspense fallback={<LoadingPage />}>
          <SaleDetailPrint />
        </Suspense>
      }
    />
    <Route
      path={PrivateRouteList.CASHIER.LABS}
      element={
        <Suspense fallback={<LoadingPage />}>
          <Laboratory />
        </Suspense>
      }
    />
  </Route>
)
