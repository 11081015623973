import HistoryTimelineItem from '../../../pages/medical_history/components/HistoryTimelineItem'
import { LaboratoryTreeView } from '@/components'

function TimeLineLaboratory({ data }) {
  return (
    <HistoryTimelineItem date={data.createdAt}>
      <LaboratoryTreeView laboratory={data} />
    </HistoryTimelineItem>
  )
}
export default TimeLineLaboratory
