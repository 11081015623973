import { axios } from '@/boot'
const path = 'v1/intern'
export const internInitalService = {
  getAreas: () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/get-areas`)
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },

  getBedsAvailable: areaId => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/get-beds-available/${areaId}`)
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },

  getDiets: () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/get-diets`)
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },

  getTemplateAdmissionFormByAreaId: areaId => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/get-template-admission-forms/${areaId}`)
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },

  getTriageLevels: () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/get-triage-levels`)
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },

  getTherapeuticLimitations: () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/get-therapeutic-limitations`)
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },

  getCategoryMedicalOrder: () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/get-category-medical-order`)
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },

  getFrecuencies: () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/get-frequencies`)
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },

  getSurgeries: () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/get-surgeries`)
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  },
  getVitalSigns: () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${path}/get-vital-signs`)
        .then(resp => resolve(resp.data))
        .catch(e => reject(e))
    })
  }
}
